export function perkDesc(type) {
    //Level 1 Perks
    if (type == "Perk_1_CritChance") {
        return "Increases your chance for a Critical Hit by";
    }
    if (type == "Perk_1_AutoDamage") {
        return "Increases your Power by";
    }
    if (type == "Perk_1_Armor") {
        return "Increases your Armor by";
    }
    if (type == "Perk_1_RunSpeed") {
        return "Increases your Movement Speed by";
    }
    if (type == "Perk_1_Cooldown") {
        return "Shortens your ability's Cooldown by";
    }
    if (type == "Perk_1_Health") {
        return "Increases your Health by";
    }
    //Level 2 Perks
    if (type == "Perk_2_Axe_CritAppliesBleed") {
        return "Critical hits Wound your target";
    } else {
        return "Testing Perk Description";
    }
}

export function convertNumToRarity(num) {
    if (num == 1) {
        return "common";
    } else if (num == 2) {
        return "uncommon";
    } else if (num == 3) {
        return "rare";
    } else if (num == 4) {
        return "epic";
    } else if (num == 5) {
        return "legendary";
    }
}

export function effectClassifier(effects, statusEffects, perk) {
    // console.log(effect);
    // console.log(statusEffects);
    let rank1;
    let rank2;
    let rank3;
    let anotherRank1;
    let testRank1;
    let anotherRank2;
    let testRank2;
    let anotherRank3;
    let testRank3;

    let res1 = statusEffects.find((eff) => {
        return eff.name == effects[0];
    });
    // console.log(res1);

    if (!res1) {
        return null;
    }

    if (res1.statusEffects.length > 0) {
        if (customFilter(res1.statusEffects, "type")) {
            let classifier = customFilter(res1.statusEffects, "type");

            if (classifier.includes("buff")) {
                perk.catState = "buff";
            }
        }

        if (customFilter(res1.statusEffects, "damageMultiplier")) {
            let classifier = customFilter(
                res1.statusEffects,
                "damageMultiplier"
            );

            rank1 = ["damagetest", classifier];
        }
        if (customFilter(res1.statusEffects, "damageBuff")) {
            let classifier = customFilter(res1.statusEffects, "damageBuff");

            if (rank1 == undefined) {
                rank1 = ["damagetest", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["damagetest", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["damagetest", classifier];
            }
        }
        if (
            customFilter(res1.statusEffects, "multiplier") &&
            customFilter(res1.statusEffects, "type") ==
                "statpercentdamagebonusbuff"
        ) {
            let classifier = customFilter(res1.statusEffects, "multiplier");

            if (rank1 == undefined) {
                rank1 = ["hptodmg", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["hptodmg", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["hptodmg", classifier];
            }
        }
        if (customFilter(res1.statusEffects, "critChanceBuff")) {
            let classifier = customFilter(res1.statusEffects, "critChanceBuff");

            if (rank1 == undefined) {
                rank1 = ["crithance", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["crithance", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["crithance", classifier];
            }
        }
        if (customFilter(res1.statusEffects, "armorBuff")) {
            let classifier = customFilter(res1.statusEffects, "armorBuff");

            if (rank1 == undefined) {
                rank1 = ["armorbuff", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["armorbuff", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["armorbuff", classifier];
            }
            // rank1 = ["armorbuff", classifier];
        }
        if (customFilter(res1.statusEffects, "cooldown")) {
            let classifier = customFilter(res1.statusEffects, "cooldown");

            if (classifier < 1 || classifier > 300) {
            } else {
                if (rank1 == undefined) {
                    rank1 = ["cooldownreduce", classifier];
                } else if (anotherRank1 == undefined) {
                    anotherRank1 = ["cooldownreduce", classifier];
                } else if (testRank1 == undefined) {
                    testRank1 = ["cooldownreduce", classifier];
                }
            }

            // rank1 = ["cooldownreduce", classifier];
        }
        if (customFilter(res1.statusEffects, "time")) {
            let classifier = customFilter(res1.statusEffects, "time");
            let classifierDelay = customFilter(res1.statusEffects, "type");

            if (classifier == 2 || classifierDelay == "delay") {
            } else {
                if (rank1 == undefined) {
                    rank1 = ["cooldownreduce", classifier];
                } else if (anotherRank1 == undefined) {
                    anotherRank1 = ["cooldownreduce", classifier];
                } else if (testRank1 == undefined) {
                    testRank1 = ["cooldownreduce", classifier];
                }
            }
            // rank1 = ["cooldownreduce", classifier];
        }
        if (customFilter(res1.statusEffects, "reflectBuff")) {
            let classifier = customFilter(res1.statusEffects, "reflectBuff");

            if (rank1 == undefined) {
                rank1 = ["reflectBuff", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["reflectBuff", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["reflectBuff", classifier];
            }
            // rank1 = ["reflectBuff", classifier];
        }
        if (customFilter(res1.statusEffects, "chance")) {
            let classifier = customFilter(res1.statusEffects, "chance");

            if (rank1 == undefined) {
                rank1 = ["chance", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["chance", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["chance", classifier];
            }
            // console.log(rank1, anotherRank1, testRank1);
            // console.log(rank1, anotherRank1, testRank1);
            // rank1 = ["chance", classifier];
        }
        if (customFilter(res1.statusEffects, "cooldownAccelerationBuff")) {
            let classifier = customFilter(
                res1.statusEffects,
                "cooldownAccelerationBuff"
            );

            if (rank1 == undefined) {
                rank1 = ["cooldownAccelerationBuff", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["cooldownAccelerationBuff", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["cooldownAccelerationBuff", classifier];
            }

            // rank1 = ["cooldownAccelerationBuff", classifier];
        }
        if (customFilter(res1.statusEffects, "critDamageMultiplierBuff")) {
            let classifier = customFilter(
                res1.statusEffects,
                "critDamageMultiplierBuff"
            );
            if (rank1 == undefined) {
                rank1 = ["critdmgmulti", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["critdmgmulti", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["critdmgmulti", classifier];
            }
            // console.log(rank1, anotherRank1, testRank1);

            // rank1 = ["critdmgmulti", classifier];
            // console.log(anotherRank1);
        }
        if (customFilter(res1.statusEffects, "hpMultiplier")) {
            let classifier = customFilter(res1.statusEffects, "hpMultiplier");

            if (rank1 == undefined) {
                rank1 = ["hpMultiplier", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["hpMultiplier", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["hpMultiplier", classifier];
            }

            // rank1 = ["cooldownAccelerationBuff", classifier];
        }
        if (customFilter(res1.statusEffects, "speedBuff")) {
            let classifier = customFilter(res1.statusEffects, "speedBuff");

            if (rank1 == undefined) {
                rank1 = ["speedBuff", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["speedBuff", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["speedBuff", classifier];
            }

            // rank1 = ["cooldownAccelerationBuff", classifier];
        }
        if (customFilter(res1.statusEffects, "maxHealthBuff")) {
            let classifier = customFilter(res1.statusEffects, "maxHealthBuff");

            if (rank1 == undefined) {
                rank1 = ["maxHealthBuff", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["maxHealthBuff", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["maxHealthBuff", classifier];
            }

            // rank1 = ["cooldownAccelerationBuff", classifier];
        }
        if (customFilter(res1.statusEffects, "blockBuff")) {
            let classifier = customFilter(res1.statusEffects, "blockBuff");

            if (rank1 == undefined) {
                rank1 = ["blockBuff", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["blockBuff", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["blockBuff", classifier];
            }
        }
        if (customFilter(res1, "tickIntervalOverride")) {
            let classifier = res1.tickIntervalOverride;

            if (rank1 == undefined) {
                rank1 = ["tickIntervalOverride", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["tickIntervalOverride", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["tickIntervalOverride", classifier];
            }
        }
        if (
            customFilter(res1.statusEffects, "triggerCount") &&
            res1.name == "RollLeavesVoidRankPersistent1"
        ) {
            let classifier = customFilter(res1.statusEffects, "triggerCount");
            classifier = (classifier + 1) / 10;

            if (rank1 == undefined) {
                rank1 = ["triggerCount", classifier];
            } else if (anotherRank1 == undefined) {
                anotherRank1 = ["triggerCount", classifier];
            } else if (testRank1 == undefined) {
                testRank1 = ["triggerCount", classifier];
            }
        } else {
            // console.log(rank1, anotherRank1, testRank1);
            // return null;
        }
        if (rank1 == undefined) {
            return null;
        }
    }

    if (effects.length > 1) {
        let res2 = statusEffects.find((eff) => {
            return eff.name == effects[1];
        });

        if (res2.statusEffects.length > 0) {
            if (customFilter(res2.statusEffects, "damageMultiplier")) {
                let classifier = customFilter(
                    res2.statusEffects,
                    "damageMultiplier"
                );

                rank2 = ["damagetest", classifier];
            }
            if (customFilter(res2.statusEffects, "damageBuff")) {
                let classifier = customFilter(res2.statusEffects, "damageBuff");

                if (rank2 == undefined) {
                    rank2 = ["damagetest", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["damagetest", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["damagetest", classifier];
                }
            }
            if (
                customFilter(res2.statusEffects, "multiplier") &&
                customFilter(res2.statusEffects, "type") ==
                    "statpercentdamagebonusbuff"
            ) {
                let classifier = customFilter(res2.statusEffects, "multiplier");

                if (rank2 == undefined) {
                    rank2 = ["hptodmg", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["hptodmg", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["hptodmg", classifier];
                }
            }
            if (customFilter(res2.statusEffects, "critChanceBuff")) {
                let classifier = customFilter(
                    res2.statusEffects,
                    "critChanceBuff"
                );

                if (rank2 == undefined) {
                    rank2 = ["crithance", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["crithance", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["crithance", classifier];
                }
            }
            if (customFilter(res2.statusEffects, "armorBuff")) {
                let classifier = customFilter(res2.statusEffects, "armorBuff");

                if (rank2 == undefined) {
                    rank2 = ["armorbuff", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["armorbuff", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["armorbuff", classifier];
                }
            }
            if (customFilter(res2.statusEffects, "reflectBuff")) {
                let classifier = customFilter(
                    res2.statusEffects,
                    "reflectBuff"
                );

                if (rank2 == undefined) {
                    rank2 = ["reflectBuff", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["reflectBuff", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["reflectBuff", classifier];
                }
            }
            if (customFilter(res2.statusEffects, "cooldownAccelerationBuff")) {
                let classifier = customFilter(
                    res2.statusEffects,
                    "cooldownAccelerationBuff"
                );

                if (rank2 == undefined) {
                    rank2 = ["cooldownAccelerationBuff", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["cooldownAccelerationBuff", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["cooldownAccelerationBuff", classifier];
                }
            }
            if (customFilter(res2.statusEffects, "cooldown")) {
                let classifier = customFilter(res2.statusEffects, "cooldown");
                if (classifier < 1 || classifier > 300) {
                } else {
                    if (rank2 == undefined) {
                        rank2 = ["cooldownreduce", classifier];
                    } else if (anotherRank2 == undefined) {
                        anotherRank2 = ["cooldownreduce", classifier];
                    } else if (testRank2 == undefined) {
                        testRank2 = ["cooldownreduce", classifier];
                    }
                }
                // console.log(rank2, anotherRank2, testRank2);
            }
            if (customFilter(res2.statusEffects, "time")) {
                let classifier = customFilter(res2.statusEffects, "time");
                let classifierDelay = customFilter(res1.statusEffects, "type");

                if (classifier == 2 || classifierDelay == "delay") {
                } else {
                    if (rank2 == undefined) {
                        rank2 = ["cooldownreduce", classifier];
                    } else if (anotherRank2 == undefined) {
                        anotherRank2 = ["cooldownreduce", classifier];
                    } else if (testRank2 == undefined) {
                        testRank2 = ["cooldownreduce", classifier];
                    }
                }
            }
            if (customFilter(res2.statusEffects, "chance")) {
                let classifier = customFilter(res2.statusEffects, "chance");

                if (rank2 == undefined) {
                    rank2 = ["chance", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["chance", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["chance", classifier];
                }
                // console.log(rank2, anotherRank2, testRank2);
            }
            if (customFilter(res2.statusEffects, "critDamageMultiplierBuff")) {
                let classifier = customFilter(
                    res2.statusEffects,
                    "critDamageMultiplierBuff"
                );

                if (rank2 == undefined) {
                    rank2 = ["critdmgmulti", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["critdmgmulti", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["critdmgmulti", classifier];
                }
            }
            if (customFilter(res2.statusEffects, "hpMultiplier")) {
                let classifier = customFilter(
                    res2.statusEffects,
                    "hpMultiplier"
                );

                if (rank2 == undefined) {
                    rank2 = ["hpMultiplier", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["hpMultiplier", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["hpMultiplier", classifier];
                }
            }
            if (customFilter(res2.statusEffects, "speedBuff")) {
                let classifier = customFilter(res2.statusEffects, "speedBuff");

                if (rank2 == undefined) {
                    rank2 = ["speedBuff", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["speedBuff", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["speedBuff", classifier];
                }
            }
            if (customFilter(res2.statusEffects, "maxHealthBuff")) {
                let classifier = customFilter(
                    res2.statusEffects,
                    "maxHealthBuff"
                );

                if (rank2 == undefined) {
                    rank2 = ["maxHealthBuff", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["maxHealthBuff", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["maxHealthBuff", classifier];
                }

                // rank1 = ["cooldownAccelerationBuff", classifier];
            }
            if (customFilter(res2, "tickIntervalOverride")) {
                let classifier = res2.tickIntervalOverride;

                if (rank2 == undefined) {
                    rank2 = ["tickIntervalOverride", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["tickIntervalOverride", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["tickIntervalOverride", classifier];
                }
            }
            if (customFilter(res2.statusEffects, "blockBuff")) {
                let classifier = customFilter(res2.statusEffects, "blockBuff");

                if (rank2 == undefined) {
                    rank2 = ["blockBuff", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["blockBuff", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["blockBuff", classifier];
                }
            }
            if (
                customFilter(res2.statusEffects, "triggerCount") &&
                res2.name == "RollLeavesVoidRankPersistent2"
            ) {
                let classifier = customFilter(
                    res2.statusEffects,
                    "triggerCount"
                );
                classifier = (classifier + 1) / 10;

                if (rank2 == undefined) {
                    rank2 = ["triggerCount", classifier];
                } else if (anotherRank2 == undefined) {
                    anotherRank2 = ["triggerCount", classifier];
                } else if (testRank2 == undefined) {
                    testRank2 = ["triggerCount", classifier];
                }
            } else {
            }
            if (rank2 == undefined) {
                return null;
            }
        }

        let res3 = statusEffects.find((eff) => {
            return eff.name == effects[2];
        });

        if (res3.statusEffects.length > 0) {
            if (customFilter(res3.statusEffects, "damageMultiplier")) {
                let classifier = customFilter(
                    res3.statusEffects,
                    "damageMultiplier"
                );

                rank3 = ["damagetest", classifier];
            }
            if (customFilter(res3.statusEffects, "damageBuff")) {
                let classifier = customFilter(res3.statusEffects, "damageBuff");

                if (rank3 == undefined) {
                    rank3 = ["damagetest", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["damagetest", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["damagetest", classifier];
                }
            }
            if (
                customFilter(res3.statusEffects, "multiplier") &&
                customFilter(res3.statusEffects, "type") ==
                    "statpercentdamagebonusbuff"
            ) {
                let classifier = customFilter(res3.statusEffects, "multiplier");

                if (rank3 == undefined) {
                    rank3 = ["hptodmg", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["hptodmg", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["hptodmg", classifier];
                }
            }
            if (customFilter(res3.statusEffects, "critChanceBuff")) {
                let classifier = customFilter(
                    res3.statusEffects,
                    "critChanceBuff"
                );

                if (rank3 == undefined) {
                    rank3 = ["crithance", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["crithance", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["crithance", classifier];
                }
            }
            if (customFilter(res3.statusEffects, "armorBuff")) {
                let classifier = customFilter(res3.statusEffects, "armorBuff");

                if (rank3 == undefined) {
                    rank3 = ["armorbuff", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["armorbuff", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["armorbuff", classifier];
                }
            }
            if (customFilter(res3.statusEffects, "cooldown")) {
                let classifier = customFilter(res3.statusEffects, "cooldown");
                // console.log(rank3, res3, "hello");
                if (classifier < 1 || classifier > 300) {
                } else {
                    if (rank3 == undefined) {
                        rank3 = ["cooldownreduce", classifier];
                    } else if (anotherRank3 == undefined) {
                        anotherRank3 = ["cooldownreduce", classifier];
                    } else if (testRank3 == undefined) {
                        testRank3 = ["cooldownreduce", classifier];
                    }
                }
            }
            if (customFilter(res3.statusEffects, "chance")) {
                let classifier = customFilter(res3.statusEffects, "chance");
                // console.log(rank3, res3.statusEffects);
                if (rank3 == undefined) {
                    rank3 = ["chance", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["chance", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["chance", classifier];
                }
                // console.log(rank3, anotherRank3, testRank3, res3.statusEffects);
            }
            if (customFilter(res3.statusEffects, "time")) {
                let classifier = customFilter(res3.statusEffects, "time");
                let classifierDelay = customFilter(res1.statusEffects, "type");

                if (classifier == 2 || classifierDelay == "delay") {
                } else {
                    if (rank3 == undefined) {
                        rank3 = ["cooldownreduce", classifier];
                    } else if (anotherRank3 == undefined) {
                        anotherRank3 = ["cooldownreduce", classifier];
                    } else if (testRank3 == undefined) {
                        testRank3 = ["cooldownreduce", classifier];
                    }
                }
            }
            if (customFilter(res3.statusEffects, "reflectBuff")) {
                let classifier = customFilter(
                    res3.statusEffects,
                    "reflectBuff"
                );

                if (rank3 == undefined) {
                    rank3 = ["reflectBuff", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["reflectBuff", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["reflectBuff", classifier];
                }
            }
            if (customFilter(res3.statusEffects, "cooldownAccelerationBuff")) {
                let classifier = customFilter(
                    res3.statusEffects,
                    "cooldownAccelerationBuff"
                );

                if (rank3 == undefined) {
                    rank3 = ["cooldownAccelerationBuff", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["cooldownAccelerationBuff", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["cooldownAccelerationBuff", classifier];
                }
            }
            if (customFilter(res3.statusEffects, "critDamageMultiplierBuff")) {
                let classifier = customFilter(
                    res3.statusEffects,
                    "critDamageMultiplierBuff"
                );

                if (rank3 == undefined) {
                    rank3 = ["critdmgmulti", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["critdmgmulti", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["critdmgmulti", classifier];
                }
            }
            if (customFilter(res3.statusEffects, "hpMultiplier")) {
                let classifier = customFilter(
                    res3.statusEffects,
                    "hpMultiplier"
                );

                if (rank3 == undefined) {
                    rank3 = ["hpMultiplier", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["hpMultiplier", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["hpMultiplier", classifier];
                }

                // rank1 = ["cooldownAccelerationBuff", classifier];
            }
            if (customFilter(res3.statusEffects, "speedBuff")) {
                let classifier = customFilter(res3.statusEffects, "speedBuff");

                if (rank3 == undefined) {
                    rank3 = ["speedBuff", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["speedBuff", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["speedBuff", classifier];
                }
            }
            if (customFilter(res3.statusEffects, "maxHealthBuff")) {
                let classifier = customFilter(
                    res3.statusEffects,
                    "maxHealthBuff"
                );

                if (rank3 == undefined) {
                    rank3 = ["maxHealthBuff", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["maxHealthBuff", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["maxHealthBuff", classifier];
                }

                // rank1 = ["cooldownAccelerationBuff", classifier];
            }
            if (customFilter(res3, "tickIntervalOverride")) {
                let classifier = res3.tickIntervalOverride;

                if (rank3 == undefined) {
                    rank3 = ["tickIntervalOverride", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["tickIntervalOverride", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["tickIntervalOverride", classifier];
                }
            }
            if (customFilter(res3.statusEffects, "blockBuff")) {
                let classifier = customFilter(res3.statusEffects, "blockBuff");

                if (rank3 == undefined) {
                    rank3 = ["blockBuff", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["blockBuff", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["blockBuff", classifier];
                }
            }
            if (
                customFilter(res3.statusEffects, "triggerCount") &&
                res3.name == "RollLeavesVoidRankPersistent3"
            ) {
                let classifier = customFilter(
                    res3.statusEffects,
                    "triggerCount"
                );
                classifier = (classifier + 1) / 10;

                if (rank3 == undefined) {
                    rank3 = ["triggerCount", classifier];
                } else if (anotherRank3 == undefined) {
                    anotherRank3 = ["triggerCount", classifier];
                } else if (testRank3 == undefined) {
                    testRank3 = ["triggerCount", classifier];
                }
            } else {
            }
            if (rank3 == undefined) {
                return null;
            }
        }
        // console.log(anotherRank1);
        // if (rank1 != null) {
        //     perk.classifier = rank1;
        // }
        // perk.anotherRankClassifier = [];
        // perk.testRankClassifier = [];

        if (anotherRank1 != null) {
            perk.anotherRankClassifier = anotherRank1;
        }
        if (testRank1 != null) {
            perk.testRankClassifier = testRank1;
        }

        // if (rank2 != null) {
        //     perk.classifier.push(rank2);
        // }
        if (anotherRank2 != null) {
            if (perk.anotherRankClassifier == null) {
                perk.anotherRankClassifier = [];
            }
            perk.anotherRankClassifier.push(anotherRank2);
        }

        if (testRank2 != null) {
            if (perk.testRankClassifier == null) {
                perk.testRankClassifier = [];
            }
            perk.testRankClassifier.push(testRank2);
        }

        // if (rank3 != null) {
        //     perk.classifier.push(rank3);
        // }
        if (anotherRank3 != null) {
            perk.anotherRankClassifier.push(anotherRank3);
        }
        if (testRank3 != null) {
            perk.testRankClassifier.push(testRank3);
        }
        // return `${rank1}_${rank2}_${rank3}`;

        if (rank1?.[1] === rank2?.[1] && rank1?.[1] === rank3?.[1]) {
            perk.flag = "flagged";
        }
        if (anotherRank1) {
            if (
                anotherRank1?.[1] == anotherRank2?.[1] &&
                anotherRank1?.[1] == anotherRank3?.[1]
            ) {
                perk.flag = "flagged";
            }
        }
        if (testRank1) {
            if (
                testRank1?.[1] == testRank2?.[1] &&
                testRank1?.[1] == testRank3?.[1]
            ) {
                perk.flag = "flagged";
            }
        }

        return `${rank1?.[0]}_${rank1?.[1].toFixed(4)}_${rank2?.[1].toFixed(
            4
        )}_${rank3?.[1].toFixed(4)}`;
    } else {
        if (anotherRank1 != null) {
            perk.anotherRankClassifier = anotherRank1;
        }
        if (testRank1 != null) {
            perk.testRankClassifier = testRank1;
        }
        return `${rank1?.[0]}_${rank1?.[1].toFixed(4)}`;
    }
}

export function perkClassifier(perk) {
    // console.log(perk.perk.modifyLevels != undefined);

    if (perk.perk.name == "Perk_4_Axe_Holy_ArmorShards2") {
        perk.extra = "armorshards";
    }
    if (perk.perk.name == "Perk_4_Axe_Holy_ArmorShards2") {
        return;
    }

    if (perk.perk.name == "Perk_4_Axe_Ice_WhirlingShards") {
        perk.extra = "iceshards";
    }

    if (perk.perk.name.includes("IncreaseDuration")) {
        perk.classifier.push("increase_duration");
    }
    if (
        perk.perk.iconRef.includes("sphere") ||
        perk.perk.iconRef.includes("perk_uncommon_spinning_shield")
    ) {
        perk.classifier.push(
            `elementsphere_damage_multi_${perk.perk.orbital.effects[0].damageMultiplier.toFixed(
                2
            )}`
        );
    }

    if (perk.perk.name == "Perk_2_Bow_Holy_PassiveStacksIncrease") {
        perk.effectClass = `applyholylight_1_2_3`;
    }

    if (
        perk.perk.name == "Perk_2_Sword_Fire_Kill_Damage" ||
        perk.perk.name == "Perk_2_Sword_Kill_Cooldown" ||
        perk.perk.name == "Perk_2_Axe_KillDamagePersistent" ||
        perk.perk.name == "Perk_4_Rage"
    ) {
        perk.apply = "ondefeat";
    }

    if (perk.perk.maxAllowed == 3 && perk.perk.percent != undefined) {
        perk.effectClass = `specialpercent_${perk.perk.percent}_${
            perk.perk.percent * 2
        }_${perk.perk.percent * 3}`;
    }

    if (perk.perk.statusLevels?.[0] == "BleedEffect") {
        perk.classifier.push(`applybleed`);
    }

    if (perk.perk.name == "Perk_4_Bow_Lightning_StruckReduceCooldown") {
        perk.effectClass = "cooldownreduce_1.00_2.00_3.00";
    }

    if (perk.perk.name == "Perk_4_Axe_Dark_GravityWell") {
        let classifier = customFilter(
            perk.perk.additiveModification.addedEffects[0],
            "distance"
        );
        perk.testRankClassifier = ["vortex", Math.abs(classifier)];
    }

    if (perk.perk.name == "Perk_4_Axe_Dark_Melee_Range") {
        perk.classifier.push("increaseabilityrange");
    }
    if (perk.perk.name == "Perk_3_Axe_Holy_Melee_Range") {
        perk.classifier.push("increaseabilityrange");
    }

    // if (perk.perk.name == "Perk_3_Ice_ReduceCooldownOnChilled") {
    //     perk.anotherRankClassifier = ["cooldownreduce", 0.33];
    // }

    //sphere stuff
    if (perk.perk.name == "Perk_2_SpinningFire") {
        perk.classifier.push(`applyburn`);
    }
    if (perk.perk.name == "Perk_2_SpinningLightning") {
        perk.classifier.push(`applystatic`);
    }
    if (perk.perk.name == "Perk_2_SpinningSpikeballs") {
        perk.classifier.push(`applymark`);
    }
    if (perk.perk.name == "Perk_2_SpinningDark") {
        perk.classifier.push(`applycurse`);
    }
    if (
        perk.perk.name == "Perk_2_SpinningIce" ||
        perk.perk.name == "Perk_3_Ice_SpinningOrbs"
    ) {
        perk.classifier.push(`applychill`);
    }
    if (perk.perk.name == "Perk_2_SpinningHoly") {
        perk.classifier.push(`applyinspire`);
    }

    if (perk.perk.name == "Perk_4_Holy_GuardianAngel") {
        perk.classifier = [];
        perk.effectClass = "";
    }
    if (perk.perk.name == "Perk_4_Sword_Dark_ShadowShield") {
        perk.classifier.push("elementspherereflect");
    }

    if (perk.perk.name == "Perk_2_Sword_RollGivesBigHit") {
        perk.classifier.push("bighit");
    }

    if (perk.perk.name == "Perk_5_Generic_Holy_Halos") {
        perk.testRankClassifier = ["damagetestheal", -1.0];
    }

    if (perk.perk.name == "Perk_3_Bow_IncreaseRainRadius") {
        let classifier = customFilter(
            perk.perk.additiveModification,
            "damageMultiplier"
        );
        perk.classifier = [
            `damagemulti_${classifier}_${(classifier * 2).toFixed(2)}_${(
                classifier * 3
            ).toFixed(2)}`,
        ];

        let extraClassifier =
            perk.perk.additiveModification.increaseProjectileSize;
        // console.log(extraClassifier);

        perk.extra = [
            `radius_${extraClassifier}_${extraClassifier * 2}_${
                extraClassifier * 3
            }`,
        ];
    }

    if (perk.perk.name == "Perk_3_Staff_Dark_Vortex") {
        let classifier = customFilter(
            perk.perk.additiveModification,
            "damageMultiplier"
        );
        perk.classifier = [
            `damagemulti_${classifier}_${(classifier * 2).toFixed(2)}_${(
                classifier * 3
            ).toFixed(2)}`,
        ];
    }

    if (perk.perk.name == "Perk_4_Sword_Fire_LeapArea") {
        let classifier = customFilter(
            perk.perk.additiveModification,
            "damageMultiplier"
        );
        perk.classifier = [
            `damagemulti_${classifier}_${(classifier * 2).toFixed(2)}_${(
                classifier * 3
            ).toFixed(2)}`,
        ];

        perk.classifier.push("increaseprojectilerange");
    }

    if (perk.perk.name == "Perk_5_Sword_Fire_LongLeap") {
        if (customFilter(perk.perk.modifyLevels, "increaseAbilityRange")) {
            let classifier = customFilter(
                perk.perk.modifyLevels,
                "increaseAbilityRange"
            );
            perk.extra = [`radius_${classifier}`];

            // perk.effectClass = `cooldownreduce_${classifier.toFixed(2)}_${(
            //     classifier + 1
            // ).toFixed(2)}_${(classifier + 2).toFixed(2)}`;
        }
    }

    if (perk.perk.name == "Perk_4_IceSculpture") {
        if (customFilter(perk.perk.modifyLevels, "hpMultiplier")) {
            let classifier1 = customFilter(
                perk.perk.modifyLevels[0].addedEffects,
                "hpMultiplier"
            );
            let classifier2 = customFilter(
                perk.perk.modifyLevels[1].addedEffects,
                "hpMultiplier"
            );
            let classifier3 = customFilter(
                perk.perk.modifyLevels[2].addedEffects,
                "hpMultiplier"
            );
            perk.extra = `hpMultiplier_${classifier1}_${classifier2}_${classifier3}`;

            // perk.effectClass = `cooldownreduce_${classifier.toFixed(2)}_${(
            //     classifier + 1
            // ).toFixed(2)}_${(classifier + 2).toFixed(2)}`;
        }
    }

    if (perk.perk.name == "Perk_4_Staff_Lightning_EmpoweredTotems") {
        if (customFilter(perk.perk.modifyLevels, "hpMultiplier")) {
            let classifier1 = customFilter(
                perk.perk.modifyLevels[0].abilitySwap,
                "hpMultiplier"
            );

            perk.anotherRankClassifier = [`hpMultiplier`, classifier1];

            // perk.effectClass = `cooldownreduce_${classifier.toFixed(2)}_${(
            //     classifier + 1
            // ).toFixed(2)}_${(classifier + 2).toFixed(2)}`;
        }
    }

    if (
        perk.perk.modifyLevels?.[0]?.addedEffects?.[0]?.status == "BleedEffect"
    ) {
        perk.classifier.push(`applybleed`);
    }

    // if (perk.perk.name == "Perk_4_Bow_Fire_ExplosiveDamageToBurning") {
    //     perk.classifier.push("bighit");
    // }

    if (
        perk.perk.modifyLevels?.[0]?.addedEffects?.[0]?.status ==
        "ResolveEffect"
    ) {
        perk.classifier.push(`applyresolve`);
    }

    if (
        perk.perk.modifyLevels?.[0]?.addedEffects?.[0]?.effects?.[0]
            ?.projectileEffects?.[0]?.status == "BleedEffect"
    ) {
        // console.log(perk.perk);
        perk.classifier.push(`applybleed`);
    }

    if (
        perk.perk.additiveModification !== null &&
        perk.perk.additiveModification != undefined
    ) {
        if (customFilter(perk.perk.additiveModification, "time")) {
            let classifier = customFilter(
                perk.perk.additiveModification,
                "time"
            );
            if (classifier < 0.1) {
            } else {
                perk.classifier.push(`cooldownreduce`);

                perk.effectClass = `cooldownreduce_${classifier.toFixed(2)}_${(
                    classifier + 1
                ).toFixed(2)}_${(classifier + 2).toFixed(2)}`;
            }
        }
    }

    if (
        perk.perk.additiveModification !== null &&
        perk.perk.additiveModification != undefined
    ) {
        if (
            customFilter(perk.perk.additiveModification, "increaseAbilityRange")
                .new > 0
        ) {
            perk.classifier.push(`increaseabilityrange`);
        }
    }

    if (
        perk.perk.modifyLevels?.[0] !== null &&
        perk.perk.modifyLevels?.[0] != undefined &&
        customFilter(perk.perk.modifyLevels?.[0], "chance") != undefined
    ) {
        let rank1;
        let rank2;
        let rank3;

        if (customFilter(perk.perk.modifyLevels?.[0], "chance")) {
            let classifier = customFilter(
                perk.perk.modifyLevels?.[0],
                "chance"
            );

            rank1 = ["chance", classifier.toFixed(2)];
        }
        if (
            perk.perk.modifyLevels?.[1] !== null &&
            perk.perk.modifyLevels?.[1] != undefined
        ) {
            if (customFilter(perk.perk.modifyLevels?.[1], "chance")) {
                let classifier = customFilter(
                    perk.perk.modifyLevels?.[1],
                    "chance"
                );

                rank2 = ["chance", classifier.toFixed(2)];
            }

            if (customFilter(perk.perk.modifyLevels?.[2], "chance")) {
                let classifier = customFilter(
                    perk.perk.modifyLevels?.[2],
                    "chance"
                );

                rank3 = ["chance", classifier.toFixed(2)];
            }
            if (perk.perk.name == "Perk_2_Sword_Flurry") {
                perk.extra = `${rank1?.[0]}_${rank1?.[1]}_${rank2?.[1]}_${rank3?.[1]}`;
            }

            perk.effectClass = `${rank1?.[0]}_${rank1?.[1]}_${rank2?.[1]}_${rank3?.[1]}`;
        } else {
            if (perk.perk.name == "Perk_2_Sword_Flurry") {
                perk.extra = `${rank1?.[0]}_${rank1?.[1]}`;
            }
            perk.effectClass = `${rank1?.[0]}_${rank1?.[1]}`;
        }
    }

    if (
        perk.perk.modifyLevels?.[0] !== null &&
        perk.perk.modifyLevels?.[0] != undefined &&
        customFilter(perk.perk.modifyLevels?.[0], "time") != undefined
    ) {
        let rank1;
        let rank2;
        let rank3;
        if (customFilter(perk.perk.modifyLevels?.[0], "time")) {
            let classifier = customFilter(perk.perk.modifyLevels?.[0], "time");
            let classifierDelay = customFilter(
                perk.perk.modifyLevels?.[0],
                "type"
            );
            if (classifier < 0.1 || classifierDelay == "delay") {
            } else {
                perk.classifier.push("cooldownreduce");
                rank1 = ["cooldownreduce", classifier.toFixed(2)];
            }
        }
        if (
            perk.perk.modifyLevels?.[1] !== null &&
            perk.perk.modifyLevels?.[1] != undefined
        ) {
            if (customFilter(perk.perk.modifyLevels?.[1], "time")) {
                let classifier = customFilter(
                    perk.perk.modifyLevels?.[1],
                    "time"
                );
                let classifierDelay = customFilter(
                    perk.perk.modifyLevels?.[1],
                    "type"
                );
                if (classifier < 0.1 || classifierDelay == "delay") {
                } else {
                    rank2 = ["cooldownreduce", classifier.toFixed(2)];
                }
            }

            if (customFilter(perk.perk.modifyLevels?.[2], "time")) {
                let classifier = customFilter(
                    perk.perk.modifyLevels?.[2],
                    "time"
                );
                let classifierDelay = customFilter(
                    perk.perk.modifyLevels?.[2],
                    "type"
                );
                if (classifier < 0.1 || classifierDelay == "delay") {
                } else {
                    rank3 = ["cooldownreduce", classifier.toFixed(2)];
                }
            }

            perk.effectClass = `${rank1?.[0]}_${rank1?.[1]}_${rank2?.[1]}_${rank3?.[1]}`;
        } else {
            perk.effectClass = `${rank1?.[0]}_${rank1?.[1]}`;
        }
    }

    if (
        perk.perk.modifyLevels?.[0] !== null &&
        perk.perk.modifyLevels?.[0] != undefined &&
        customFilter(perk.perk.modifyLevels?.[0], "damageMultiplier") !=
            undefined
    ) {
        let rank1;
        let rank2;
        let rank3;
        if (customFilter(perk.perk.modifyLevels?.[0], "damageMultiplier")) {
            let classifier = customFilter(
                perk.perk.modifyLevels?.[0],
                "damageMultiplier"
            );

            rank1 = ["damagemulti", classifier.toFixed(2)];
            if (perk.perk.name == "Perk_5_Generic_Axe_WhirlwindExplode") {
                rank1 = ["damagemultiwhirl", classifier.toFixed(2)];
            }
        }
        if (
            perk.perk.modifyLevels?.[1] !== null &&
            perk.perk.modifyLevels?.[1] != undefined
        ) {
            if (customFilter(perk.perk.modifyLevels?.[1], "damageMultiplier")) {
                let classifier = customFilter(
                    perk.perk.modifyLevels?.[1],
                    "damageMultiplier"
                );

                rank2 = ["damagemulti", classifier.toFixed(2)];
            }

            if (customFilter(perk.perk.modifyLevels?.[2], "damageMultiplier")) {
                let classifier = customFilter(
                    perk.perk.modifyLevels?.[2],
                    "damageMultiplier"
                );

                rank3 = ["damagemulti", classifier.toFixed(2)];
            }
            if (perk.perk.name == "Perk_3_Ice_Sword_Pool") {
                perk.effectClass = `${rank1?.[0]}_${rank1?.[1]}_${rank2?.[1]}_${rank3?.[1]}`;
                perk.classifier = [
                    `${rank1?.[0]}_${rank1?.[1]}_${rank2?.[1]}_${rank3?.[1]}`,
                ];
            }
            if (perk.perk.name == "Perk_5_Generic_Axe_WhirlwindExplode") {
                perk.extra = [
                    `${rank1?.[0]}_${rank1?.[1]}_${rank2?.[1]}_${rank3?.[1]}`,
                ];
            }
            perk.classifier.push(
                `${rank1?.[0]}_${rank1?.[1]}_${rank2?.[1]}_${rank3?.[1]}`
            );
        } else {
            if (perk.perk.name == "Perk_3_Ice_Sword_Pool") {
                perk.effectClass = `${rank1?.[0]}_${rank1?.[1]}_${rank2?.[1]}_${rank3?.[1]}`;
                perk.classifier = [
                    `${rank1?.[0]}_${rank1?.[1]}_${rank2?.[1]}_${rank3?.[1]}`,
                ];
            }
            if (perk.perk.name == "Perk_5_Generic_Axe_WhirlwindExplode") {
                perk.extra = [`${rank1?.[0]}`, `${rank1?.[1]}`];
            } else {
                perk.classifier.push(`${rank1?.[0]}_${rank1?.[1]}`);
            }
        }
    }

    if (
        perk.perk.modifyLevels?.[0] !== null &&
        perk.perk.modifyLevels?.[0] != undefined &&
        customFilter(perk.perk.modifyLevels?.[0], "distance") != undefined
    ) {
        let rank1;
        let rank2;
        let rank3;

        if (customFilter(perk.perk.modifyLevels?.[0], "distance")) {
            let classifier = customFilter(
                perk.perk.modifyLevels?.[0],
                "distance"
            );

            rank1 = ["distance", classifier.toFixed(2)];
        }
        if (
            perk.perk.modifyLevels?.[1] !== null &&
            perk.perk.modifyLevels?.[1] != undefined
        ) {
            if (customFilter(perk.perk.modifyLevels?.[1], "distance")) {
                let classifier = customFilter(
                    perk.perk.modifyLevels?.[1],
                    "distance"
                );

                rank2 = ["distance", classifier.toFixed(2)];
            }

            if (customFilter(perk.perk.modifyLevels?.[2], "distance")) {
                let classifier = customFilter(
                    perk.perk.modifyLevels?.[2],
                    "distance"
                );

                rank3 = ["distance", classifier.toFixed(2)];
            }

            perk.extra = `${rank1?.[0]}_${rank1?.[1]}_${rank2?.[1]}_${rank3?.[1]}`;
        } else {
            perk.extra = `${rank1?.[0]}_${rank1?.[1]}`;
        }
    }

    // if (
    //     perk.perk.modfiyLevels !== null &&
    //     perk.perk.modifyLevels != undefined
    // ) {
    //     if (customFilter(perk.perk.modifyLevels, "damageMultiplier")) {
    //         let classifier = customFilter(
    //             perk.perk.modifyLevels,
    //             "damageMultiplier"
    //         );

    //         perk.classifier.push(`damage_multi_${classifier.toFixed(2)}`);
    //     }
    // }

    if (
        perk.perk.aditiveModification !== null &&
        perk.perk.additiveModification != undefined
    ) {
        if (
            customFilter(
                perk.perk.additiveModification,
                "critDamageMultiplierBuff"
            )
        ) {
            let classifier = customFilter(
                perk.perk.additiveModification,
                "critDamageMultiplierBuff"
            );

            perk.classifier.push(`critdamage_${classifier.toFixed(2)}`);
        }
    }

    if (
        perk.perk.aditiveModification !== null &&
        perk.perk.additiveModification != undefined
    ) {
        if (customFilter(perk.perk.additiveModification, "damageMultiplier")) {
            let classifier = customFilter(
                perk.perk.additiveModification,
                "damageMultiplier"
            );

            perk.classifier.push(`damagemulti_${classifier.toFixed(2)}`);
        }
    }

    if (
        perk.perk.modfiyLevels !== null &&
        perk.perk.modifyLevels != undefined
    ) {
        if (customFilter(perk.perk.modifyLevels, "radius")) {
            let classifier = customFilter(perk.perk.modifyLevels, "radius");

            perk.classifier.push(`radius_${classifier.toFixed(2)}`);
        }
    }

    if (perk.perk.name == "Perk_5_Axe_Dark_WhirlSize") {
        perk.classifier.push(
            `radius_${perk.perk.additiveModification.addedEffects[0].effects[0].targeting.radius.toFixed(
                2
            )}`
        );
    }
}

export const customFilter = (object, key) => {
    // console.log("hehehe", object);
    if (Array.isArray(object)) {
        for (const obj of object) {
            const result = customFilter(obj, key);
            if (result) {
                // console.log("DWDW", result);
                if (typeof result == "number") {
                    return result;
                }

                if (result[key] != null && result[key] != undefined) {
                    // console.log(obj);
                    return result.new;
                }
                break;
                console.log(obj);
                return obj;
            }
        }
    } else {
        if (
            object.hasOwnProperty(key) &&
            object[key] != null &&
            object[key] != undefined
        ) {
            object.new = object[key];
            return object;
        }

        for (const k of Object.keys(object)) {
            if (typeof object[k] === "object" && object[k] != null) {
                const o = customFilter(object[k], key);
                if (o !== null && typeof o !== "undefined") return o;
            }
        }

        return null;
    }
};

export function filterWeaponName(weaponName, allLocs) {
    let name = allLocs.find((loc) => {
        console.log(weaponName);
        return (
            loc.key.toString().trim().split(/\s+/)[0] == weaponName &&
            loc.key.toString().trim().split(/\s+/)[1] == "NAME"
        );
    });
    let description = allLocs.find((loc) => {
        return (
            loc.key.toString().trim().split(/\s+/)[0] == weaponName &&
            loc.key.toString().trim().split(/\s+/)[1] == "DESCRIPTION"
        );
    });
    // console.log(name, description);
    return [name, description];
}

export function filterAbilityName(weapon, allLocs, weaponName) {
    let abilityName = allLocs.find((loc) => {
        return (
            loc.key.toString().trim().split(/\s+/)[0] ==
                weapon.specialAbility.displayName
                    .toString()
                    .trim()
                    .split(/\s+/)[0] &&
            loc.key.toString().trim().split(/\s+/)[1] == "NAME"
        );
    });
    let abilityDesc = allLocs.find((loc) => {
        return (
            loc.key.toString().trim().split(/\s+/)[0] ==
                weapon.specialAbility.displayName
                    .toString()
                    .trim()
                    .split(/\s+/)[0] &&
            loc.key.toString().trim().split(/\s+/)[1] == "DESCRIPTION"
        );
    });
    abilityName = abilityName["English (United States)"];
    abilityDesc = abilityDesc["English (United States)"];
    let abilityImgName = getAbilityImg(weaponName);

    //ability object done
    let passiveName = allLocs.find((loc) => {
        return (
            loc.key.toString().trim().split(/\s+/)[0] ==
                weapon.passivePerks[0].displayName
                    .toString()
                    .trim()
                    .split(/\s+/)[0] &&
            loc.key.toString().trim().split(/\s+/)[1] == "NAME"
        );
    });
    console.log(weapon.passivePerks[0].displayName);
    let passiveDesc = allLocs.find((loc) => {
        return (
            loc.key.toString().trim().split(/\s+/)[0] ==
                weapon.passivePerks[0].displayName
                    .toString()
                    .trim()
                    .split(/\s+/)[0] &&
            loc.key.toString().trim().split(/\s+/)[1] == "DESCRIPTION"
        );
    });

    passiveName = passiveName["English (United States)"];
    passiveDesc = passiveDesc["English (United States)"];
    let passiveImgName = weapon.passivePerks[0].iconRef;

    //passive object done
    let statusName = allLocs.find((loc) => {
        return (
            loc.key.toString().trim().split(/\s+/)[0] ==
                weapon.weaponStatusEffect &&
            loc.key.toString().trim().split(/\s+/)[1] == "NAME"
        );
    });
    let statusDesc = allLocs.find((loc) => {
        return (
            loc.key.toString().trim().split(/\s+/)[0] ==
                weapon.weaponStatusEffect &&
            loc.key.toString().trim().split(/\s+/)[1] == "DESCRIPTION"
        );
    });

    statusName = statusName["English (United States)"];
    statusDesc = statusDesc["English (United States)"];
    let statusImgName = getStatusImg(weaponName);

    return [
        [abilityName, abilityDesc, abilityImgName],
        [passiveName, passiveDesc, passiveImgName],
        [statusName, statusDesc, statusImgName],
    ];
}

function getAbilityImg(abilityName) {
    if (abilityName == "Balanced Sword") {
        return "royal_rush";
    } else if (abilityName == "Flaming Scimitar") {
        return "fiery_slam";
    } else if (abilityName == "Charged Katana") {
        return "leap_shock";
    } else if (abilityName == "Icy Blade") {
        return "leap_ice";
    } else if (abilityName == "Blessed Blade") {
        return "leap_light";
    } else if (abilityName == "Shade Shank") {
        return "shadow_rush";
    } else if (abilityName == "Jolt Bow") {
        return "shock_shot";
    } else if (abilityName == "Righteous Bow") {
        return "arrow_of_justice";
    } else if (abilityName == "Void Bow") {
        return "arrowrain_shadow";
    } else if (abilityName == "Blazing Bow") {
        return "fire_fan";
    } else if (abilityName == "Snow Bow") {
        return "arrowrain_ice";
    } else if (abilityName == "Royal Bow") {
        return "arrowrain_royal";
    } else if (abilityName == "Icy Axe") {
        return "path_of_frost";
    } else if (abilityName == "Void Axe") {
        return "whirlwind_shadow";
    } else if (abilityName == "Honed Battleaxe") {
        return "whirlwind_royal";
    } else if (abilityName == "Zappy Chopper") {
        return "whirlwind_shock";
    } else if (abilityName == "Inferno Axe") {
        return "whirlwind_fire";
    } else if (abilityName == "Sacred Axe") {
        return "shielding_spin";
    } else if (abilityName == "Staff of Ember") {
        return "torrent_fire";
    } else if (abilityName == "Staff of Divinity") {
        return "torrent_light";
    } else if (abilityName == "Staff of Frost") {
        return "torrent_ice";
    } else if (abilityName == "Staff of Heroes") {
        return "torrent_royal";
    } else if (abilityName == "Staff of Desolation") {
        return "torrent_dark";
    } else if (abilityName == "Staff of Storms") {
        return "lightning_totem";
    }
}

function getStatusImg(abilityName) {
    if (abilityName == "Balanced Sword") {
        return "marked";
    } else if (abilityName == "Flaming Scimitar") {
        return "fire";
    } else if (abilityName == "Charged Katana") {
        return "shock";
    } else if (abilityName == "Icy Blade") {
        return "ice";
    } else if (abilityName == "Blessed Blade") {
        return "light";
    } else if (abilityName == "Shade Shank") {
        return "shadow";
    } else if (abilityName == "Jolt Bow") {
        return "shock";
    } else if (abilityName == "Righteous Bow") {
        return "light";
    } else if (abilityName == "Void Bow") {
        return "shadow";
    } else if (abilityName == "Blazing Bow") {
        return "fire";
    } else if (abilityName == "Snow Bow") {
        return "ice";
    } else if (abilityName == "Royal Bow") {
        return "marked";
    } else if (abilityName == "Icy Axe") {
        return "ice";
    } else if (abilityName == "Void Axe") {
        return "shadow";
    } else if (abilityName == "Honed Battleaxe") {
        return "marked";
    } else if (abilityName == "Zappy Chopper") {
        return "shock";
    } else if (abilityName == "Inferno Axe") {
        return "fire";
    } else if (abilityName == "Sacred Axe") {
        return "light";
    } else if (abilityName == "Staff of Ember") {
        return "fire";
    } else if (abilityName == "Staff of Divinity") {
        return "light";
    } else if (abilityName == "Staff of Frost") {
        return "ice";
    } else if (abilityName == "Staff of Heroes") {
        return "marked";
    } else if (abilityName == "Staff of Desolation") {
        return "shadow";
    } else if (abilityName == "Staff of Storms") {
        return "shock";
    }
}
