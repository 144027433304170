import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
} from "react-router-dom";

import axios from "axios";
import Filter from "./Filter";
import {
    convertNumToRarity,
    effectClassifier,
    filterWeaponName,
    perkClassifier,
    filterAbilityName,
} from "./utils";
import { exception } from "./exceptions";
import { returndiv, testcooldown } from "./cssutils";

import { createPerkDiv } from "./createPerkDiv";

import "./Weapons.css";
const { innerWidth: screenWidth, innerHeight: screenHeight } = window;

const endpointURL =
    "https://us-central1-dungeoneer-prod.cloudfunctions.net/communityContentDetails";

function Weapons() {
    const [isLoading, setLoading] = useState(true);
    const [weapons, setWeapons] = useState([]);
    const [statusEffects, setStatusEffects] = useState([]);

    const [arrayOfLocs, setArrayOfLocs] = useState([]);
    const [allLocs, setAllLocs] = useState([]);

    let navigate = useNavigate();

    var test;

    var rarityOne = "rarityOne";
    var rarityTwo = "rarityTwo";
    var rarityThree = "rarityThree";
    var rarityFour = "rarityFour";
    var rarityFive = "rarityFive";

    // var arrayOfLocs = [];

    useEffect(() => {
        axios.get("lfglocs.json").then((response) => {
            filterLocsForPerks(response.data);
            setAllLocs(response.data);
        });
        console.log(screenWidth);

        axios.get(endpointURL).then((response) => {
            let testData = response.data.statusEffects.filter((effect) => {
                return effect.name.includes("VampiricEffect");
            });
            console.log(testData);
            setStatusEffects(response.data.statusEffects);
            filterWeapons(response.data, response.data.statusEffects);

            setLoading(false);
        });
    }, []);

    function filterLocsForPerks(data) {
        var locsWithPerks = data.filter((loc) => {
            return loc["key"].includes("Perk");
        });

        setArrayOfLocs(locsWithPerks);
    }
    function filterWeapons(data, statusEffects) {
        let weaponsData = data.weapons;
        var weaponsWithPerks = weaponsData.filter((weapon) => {
            return weapon.perks.length > 0;
        });
        weaponsWithPerks = weaponsWithPerks.filter((weapon) => {
            return (
                !weapon.$path.includes("Old") &&
                !weapon.$path.includes("New") &&
                !weapon.$path.includes("RuneBalance")
            );
        });
        setWeapons(weaponsWithPerks);

        // console.log(weaponsWithPerks.length);
        // for (let i = 0; i < weaponsWithPerks.length; i++) {
        //     // console.log(i);
        //     filterPerks(
        //         weaponsWithPerks[i],
        //         statusEffects,
        //         weaponsWithPerks[i].name
        //     );
        // }
    }

    function onPress(weaponName) {
        console.log(weaponName);
        let currentWeapon;
        currentWeapon = weapons.filter((weapon) => {
            return weapon.name == weaponName;
        });
        // let thediv = filterPerks(currentWeapon[0], statusEffects, weaponName);
        // console.log(thediv);
        let weaponLocs = filterWeaponName(weaponName, allLocs);
        let abilityPassive = filterAbilityName(
            currentWeapon[0],
            allLocs,
            weaponLocs[0]["English (United States)"]
        );
        console.log(abilityPassive);
        // let ol = "<div><p>Hi</p></div>";
        navigate("/perks", {
            state: {
                currentWeapon: currentWeapon[0],
                statusEffects: statusEffects,
                weaponName: weaponName,
                arrayOfLocs: arrayOfLocs,
                displayName: weaponLocs[0]["English (United States)"],
                displayDesc: weaponLocs[1]["English (United States)"],
                abilityPassive: abilityPassive,
            },
        });
    }

    function filterPerks(weapon, statusEffects, weaponName) {
        // console.log(rarity);

        // console.log(weaponsWithPerks);

        var perksWithRarity = [];
        var arrayOfPerks = [];

        //filtering for a specific rarity of perk

        // eachWeapon(weaponsWithPerks[i]);
        console.log(weapon);
        perksWithRarity = weapon.perks;
        console.log(perksWithRarity);

        for (var perkI of perksWithRarity) {
            arrayOfPerks.push(perkI);
        }

        // console.log(arrayOfPerks);

        //Checks if perks have any duplicates, returns new array with only unique perks
        let uniquePerks = [];
        var unique = arrayOfPerks.filter((element) => {
            const isDuplicate = uniquePerks.includes(element.perk.name);

            if (!isDuplicate) {
                uniquePerks.push(element.perk.name);

                return true;
            }

            return false;
        });

        //only use images that have "perk" in it to avoid missing images
        unique = unique.filter((el) => {
            return el.perk.iconRef.includes("perk");
        });

        // var dad = unique.filter((el) => {
        //     // return el.perk.rarity == 2;
        //     return el.perk.name.includes("Perk_2_Bow_ProjectilesSplit");
        //     return el.perk.statusLevels != null;
        // });
        // console.log("DDDDDDDDDD", dad);

        //adding the name and description localizations to the perk object
        for (let i = 0; i < unique.length; i++) {
            unique[i].classifier = [];
            // console.log("hdwdw", arrayOfLocs);
            // console.log(unique[i].perk.name);
            if (unique[i].perk.name == "Perk_2_Bow_ProjectilesSplit15") {
                unique[i].perk.name = "Perk_2_Bow_ProjectilesSplit";
            }

            let result = arrayOfLocs.find((loc) => {
                // return loc.name.key[0] == "Perk_2_Bow_ProjectilesSplit";
                // console.log(loc);
                return loc.name.key[0] == unique[i].perk.name;
            });

            // console.log(result);

            exception(unique[i], statusEffects);

            perkClassifier(unique[i]);

            if (!result) {
                unique.splice(i, 1);
                i -= 1;
            } else {
                unique[i].locs = result;
            }
        }

        let test = unique.filter((perk) => {
            // return perk.perk.iconRef.includes("fire");
            return perk.locs.name["English (United States)"].includes(
                "Doom"
                //DASH
            );
        });
        // console.log(test)
        console.log(unique);
        test = createPerkDiv(unique, weaponName);

        return test;
    }

    if (isLoading == true) {
        return (
            <div
                style={{
                    backgroundColor: "#180f34",
                    height: screenHeight,
                    width: screenWidth,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h1>Entering the World...</h1>
            </div>
        );
    } else {
        return (
            <div className="weaponsPage">
                <div
                    className="topBlueBar"
                    style={{
                        backgroundColor: "#4626C8",
                        width: 750,
                        height: 130,
                        borderRadius: 10,
                        display: isLoading == true ? "none" : "flex",
                        flexDirection: "column",
                        alignContent: "center",
                        marginTop: 20,
                    }}
                >
                    <h1
                        className="topBlueBarText"
                        style={{
                            paddingTop: 20,
                            marginBottom: 10,
                            fontSize: 50,
                            color: "#FEF7BA",
                            textAlign: "center",
                        }}
                    >
                        Knight's Edge Perks Balance
                    </h1>
                    <p
                        style={{
                            fontStyle: "italic",
                            marginBottom: 100,
                            textAlign: "center",
                            color: "#D0CEE0",
                        }}
                    >
                        Last updated: <b>Friday, Aug 12th, 2022</b>
                    </p>
                </div>

                <div
                    className="weaponContainer"
                    style={{ marginTop: 50, marginBottom: 100 }}
                >
                    {weapons &&
                        weapons.map((weapon) => {
                            let imgSrc = require(`./assets/weaponImages/${weapon.iconRef}.png`);
                            return (
                                <a onClick={() => onPress(weapon.name)}>
                                    <div className="individualWeapon">
                                        <img
                                            src={imgSrc}
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                            }}
                                        />
                                        {/* <h1>{weapon.name}</h1> */}
                                    </div>
                                </a>
                            );
                        })}
                </div>
                <div id="test">{test}</div>
            </div>
        );
    }
}

export default Weapons;
