import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Home";
import Weapons from "./Weapons";
import Perks from "./Perks";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Weapons />} />
                <Route path="/perks" element={<Perks />} />
                {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            </Routes>
        </Router>
    );
}

export default App;
