export function returndiv(classifier, field, description, icon, ranks) {
    return classifier && classifier != "undefined" && classifier.includes(field)
        ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
              "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
              "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
              icon +
              ">" +
              "<p class='statboxtext'> " +
              "(On Hit)</p></div></a> <div class='popover__content'>" +
              `<h1 class='popover__message' >${description}</h1></div></div>` +
              "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px; margin-top: 20px; margin-bottom: 20px'>" +
              "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
              icon +
              ">" +
              "<p class='statboxtext'> " +
              "(On Hit)</p></div></a> <div class='popover__content'>" +
              `<h1 class='popover__message' >${description}</h1></div></div>` +
              "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px; '>" +
              "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
              icon +
              ">" +
              "<p class='statboxtext'> " +
              "(On Hit)</p></div></a> <div class='popover__content'>" +
              `<h1 class='popover__message' >${description}</h1></div></div>` +
              "</div>"
        : "";
}

export function testcooldown(
    classifier,
    field,
    description,
    icon,
    anotherRank = false,
    testRank = false,
    symbol = "",
    multiplier = 1,
    extra = null,
    imgSize = 64,
    onText = "",
    styleException = false
) {
    if (anotherRank) {
        classifier = `${anotherRank[0]}_${anotherRank[1]}`;
    }
    if (testRank) {
        classifier = `${testRank[0]}_${testRank[1]}`;
    }
    if (extra) {
        extra = extra.split(/[-_]+/);
        classifier = `${extra[0]}_${extra[1]}_${extra[2]}_${extra[3]}`;
    }

    if (anotherRank.length == 4) {
        classifier = `${anotherRank[0]}_${anotherRank[1]}_${anotherRank[2][1]}_${anotherRank[3][1]}`;
        // console.log(classifier);
    }
    if (testRank.length == 4) {
        classifier = `${testRank[0]}_${testRank[1]}_${testRank[2][1]}_${testRank[3][1]}`;
    }
    let stylediv = "";
    let margin = "";
    let flag = false;

    if (styleException) {
        stylediv = "width: 150px; height: 64px";
        margin = "style='margin-left: 5px'";
    }
    if (classifier.split(/[_]+/).length > 3) {
        if (
            classifier.split(/[_]+/)[1] == classifier.split(/[_]+/)[2] &&
            classifier.split(/[_]+/)[1] == classifier.split(/[_]+/)[3]
        ) {
            flag = true;
        }
    }
    return classifier
        ? // classifier.includes(field)
          "<div style='display: flex; flex-direction:column; align-items: center'>" +
              (flag ? "<h1>FLAGGED</h1>" : "") +
              `<div class='popover__wrapper'><a ><div class='statbox popover__title' style='${stylediv}'>` +
              `<img  style='width: ${imgSize}px; height: ${imgSize}px' src=` +
              icon +
              ">" +
              `<p class='statboxtext' ${margin}> ` +
              parseFloat(classifier.split(/[_]+/)[1]) * multiplier +
              `${symbol} ${onText}</p></div></a> <div class='popover__content'>` +
              `<h1 class='popover__message' >${description}</h1></div></div>` +
              (classifier.split(/[_]+/)[2]
                  ? `<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px; ${stylediv}' >` +
                    `<img style='width: ${imgSize}px; height: ${imgSize}px' src=` +
                    icon +
                    ">" +
                    `<p class='statboxtext' ${margin}> ` +
                    Number(parseFloat(classifier.split(/[_]+/)[2])) *
                        multiplier +
                    `${symbol} ${onText}</p></div></a> <div class='popover__content'>` +
                    `<h1 class='popover__message' >${description}</h1></div></div>`
                  : "") +
              (classifier.split(/[_]+/)[3]
                  ? `<div class='popover__wrapper' ><a ><div class='statbox popover__title' style='${stylediv}' >` +
                    `<img style='width: ${imgSize}px; height: ${imgSize}px' src=` +
                    icon +
                    ">" +
                    `<p class='statboxtext' ${margin}>` +
                    parseFloat(classifier.split(/[_]+/)[3]) * multiplier +
                    `${symbol} ${onText}</p></div></a> <div class='popover__content'>` +
                    `<h1 class='popover__message' >${description}</h1></div></div>`
                  : "") +
              "</div>"
        : "";
}
