import { convertNumToRarity, effectClassifier, perkClassifier } from "./utils";
export function exception(unique, statusEffects) {
    if (unique.perk.statusLevels != null) {
        // console.log(unique.perk.statusLevels[0]);
        if (unique.perk.statusLevels[0] == "BonusInspirePersistentRank1") {
            unique.perk.statusLevels = [
                "BonusInspireDamageRank1",
                "BonusInspireDamageRank2",
                "BonusInspireDamageRank3",
            ];
        }

        if (unique.perk.statusLevels[0] == "RollGivesCritPersistentRank1") {
            unique.perk.statusLevels = [
                "RollGivesCritRank1",
                "RollGivesCritRank2",
                "RollGivesCritRank3",
            ];
        }

        if (unique.perk.statusLevels[0] == "RollGivesArmorPersistentRank1") {
            unique.perk.statusLevels = [
                "RollGivesArmorRank1",
                "RollGivesArmorRank2",
                "RollGivesArmorRank3",
            ];
        }

        if (
            unique.perk.statusLevels[0] ==
            "StatusEffect_Perk_4_RenewingFirePersistent1"
        ) {
            unique.perk.statusLevels = [
                "StatusEffect_Perk_4_RenewingFireStacks1",
                "StatusEffect_Perk_4_RenewingFireStacks2",
                "StatusEffect_Perk_4_RenewingFireStacks3",
            ];
        }

        if (
            unique.perk.statusLevels[0] == "NearbyBurningHealsPersistentRank1"
        ) {
            unique.perk.statusLevels = [
                "NearbyBurningHealsRank1",
                "NearbyBurningHealsRank2",
                "NearbyBurningHealsRank3",
            ];
        }

        if (
            unique.perk.statusLevels[0] ==
            "Buff_Perk_5_Generic_Lightning_ArcOnKill"
        ) {
            unique.perk.statusLevels = [
                "Buff_Perk_5_Generic_Lightning_ArcReady",
            ];
        }

        if (
            unique.perk.statusLevels[0] ==
            "Buff_Perk_5_Generic_Fire_RageStacksPersistent"
        ) {
            unique.perk.statusLevels = ["Buff_Perk_5_Generic_Fire_RageStacks"];
        }

        if (
            unique.perk.statusLevels[0] == "OnStatusGiveDamagePersistentRank1"
        ) {
            unique.perk.statusLevels = [
                "OnStatusGiveDamageRank1",
                "OnStatusGiveDamageRank2",
                "OnStatusGiveDamageRank3",
            ];
        }
        if (unique.perk.statusLevels[0] == "OnStatusGiveCritPersistentRank1") {
            unique.perk.statusLevels = [
                "OnStatusGiveCritRank1",
                "OnStatusGiveCritRank2",
                "OnStatusGiveCritRank3",
            ];
        }

        if (
            unique.perk.statusLevels[0] == "StruckIncreaseArmorPersistentRank1"
        ) {
            unique.perk.statusLevels = [
                "StruckIncreaseArmorRank1",
                "StruckIncreaseArmorRank2",
                "StruckIncreaseArmorRank3",
            ];
        }

        if (
            unique.perk.statusLevels[0] ==
            "OnHitBurningIncreaseDmgPersistentRank1"
        ) {
            unique.perk.statusLevels = [
                "OnHitBurningIncreaseDmgRank1",
                "OnHitBurningIncreaseDmgRank2",
                "OnHitBurningIncreaseDmgRank3",
            ];
        }
        if (
            unique.perk.statusLevels[0] ==
            "BlockingIncreasesDamageRankPersistent1"
        ) {
            unique.perk.statusLevels = [
                "BlockingInceasesDamageRank1",
                "BlockingInceasesDamageRank2",
                "BlockingInceasesDamageRank3",
            ];
        }

        if (
            unique.perk.statusLevels[0] ==
            "CritsIncreaseCritChancePersistentRank1"
        ) {
            unique.perk.statusLevels = [
                "CritsIncreaseCritChanceBuffRank1",
                "CritsIncreaseCritChanceBuffRank2",
                "CritsIncreaseCritChanceBuffRank3",
            ];
        }

        if (unique.perk.statusLevels[0] == "OnHitBonusDamagePersistentRank1") {
            unique.perk.statusLevels = [
                "OnHitBonusDamageRank1",
                "OnHitBonusDamageRank2",
                "OnHitBonusDamageRank3",
            ];
        }

        if (
            unique.perk.statusLevels[0] ==
            "StatusEffect_Perk_4_ColdBlooded_PersistentRank1"
        ) {
            unique.perk.statusLevels = [
                "ColdBloodedRank1",
                "ColdBloodedRank2",
                "ColdBloodedRank3",
            ];
        }

        if (
            unique.perk.statusLevels[0] ==
            "StatusEffect_BurningGivesCritDamageBoost_PersistentRank1"
        ) {
            unique.perk.statusLevels = [
                "StatusEffect_BurnGivesCritDamageBoost_Rank1",
                "StatusEffect_BurnGivesCritDamageBoost_Rank2",
                "StatusEffect_BurnGivesCritDamageBoost_Rank3",
            ];
        }

        if (unique.perk.statusLevels[0] == "RagePersistentRank1") {
            unique.perk.statusLevels = ["RageRank1", "RageRank2", "RageRank3"];
        }

        if (
            unique.perk.statusLevels[0] ==
            "StatusEffect_HitsOnLowHPGiveArmor_PersistentRank1"
        ) {
            unique.perk.statusLevels = [
                "HitsOnLowHPGiveArmorRank1",
                "HitsOnLowHPGiveArmorRank2",
                "HitsOnLowHPGiveArmorRank3",
            ];
        }

        if (
            unique.perk.statusLevels[0] ==
            "OnStatusBurningGiveMoveSpeedPersistentRank1"
        ) {
            unique.perk.statusLevels = [
                "OnStatusBurningGiveMoveSpeedRank1",
                "OnStatusBurningGiveMoveSpeedRank2",
                "OnStatusBurningGiveMoveSpeedRank3",
            ];
        }

        if (unique.perk.statusLevels[0] == "LowHPDamageBonusPersistentRank1") {
            unique.perk.statusLevels = [
                "LowHPDamageBonusRank1",
                "LowHPDamageBonusRank2",
                "LowHPDamageBonusRank3",
            ];
        }

        if (
            unique.perk.statusLevels[0] ==
            "OnStatusGiveCritDamagePersistentRank1"
        ) {
            unique.perk.statusLevels = [
                "OnStatusGiveCritDamageRank1",
                "OnStatusGiveCritDamageRank2",
                "OnStatusGiveCritDamageRank3",
            ];
        }

        if (unique.perk.statusLevels[0] == "KillGivesDamagePersistentRank1") {
            unique.perk.statusLevels = ["KillGivesDamageRank1"];
        }
        if (unique.perk.statusLevels[0] == "BuffBleedOnCrit") {
            unique.classifier.push("applybleed");
        }
        if (unique.perk.statusLevels[0] == "BuffInspireOnCrit") {
            unique.classifier.push("applyinspireoncrit");
        }
        if (unique.perk.statusLevels[0] == "BuffStaticOnCrit") {
            unique.classifier.push("applystaticoncrit");
        }
        if (unique.perk.statusLevels[0] == "BuffBurnOnCrit") {
            unique.classifier.push("applyburnoncrit");
        }
        if (unique.perk.statusLevels[0] == "BuffCurseOnCrit") {
            unique.classifier.push("applycurseoncrit");
        }
        if (unique.perk.statusLevels[0] == "BuffChillOnCrit") {
            unique.classifier.push("applychilloncrit");
        }
        if (unique.perk.statusLevels[0] == "BuffMarkOnCrit") {
            unique.classifier.push("applymarkoncrit");
        }
        if (unique.perk.statusLevels[0] == "PulsingFireRank1") {
            unique.classifier.push("applyburn");
        }
        if (unique.perk.statusLevels[0] == "PulsingLightningRank1") {
            unique.classifier.push("applystatic");
        }
        if (unique.perk.statusLevels[0] == "PulsingSpikesRank1") {
            unique.classifier.push("applymark");
        }
        if (unique.perk.statusLevels[0] == "PulsingDarkRank1") {
            unique.classifier.push("applycurse");
        }
        if (unique.perk.statusLevels[0] == "PulsingIceRank1") {
            unique.classifier.push("applychill");
        }
        if (unique.perk.statusLevels[0] == "PulsingHolyRank1") {
            unique.classifier.push("applyinspire");
        }
        if (unique.perk.name == "Perk_5_Sword_Royal_AncientSword") {
            unique.perk.statusLevels = ["AncientSwordBuff"];
        }

        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        //SPECIAL CASE
        // if (
        //     unique.perk.status ==
        //     "StatusEffect_Perk_2_Bow_Holy_PassiveStacksIncrease"
        // ) {
        //     unique.perk.statusLevels = ["StatusEffect_BowHolyLightStacks"];
        //     let res1 = statusEffects.find((eff) => {
        //         return eff.name == unique.perk.statusLevels[0];
        //     });
        //     classed = `crithance_${res1.statusEffects[0].critChanceBuff}_${res1.statusEffects[1].critChanceBuff}_${res1.statusEffects[2].critChanceBuff}`;
        // }
        //SPECIAL CASE

        // console.log(classed);
        // unique.clasifier.push(classed.split(/[-_]+/)[0])
        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_3_Ice_OnPotionPermanentArmor") {
        unique.perk.statusLevels = [
            "OnPotionArmorBonusRank1",
            "OnPotionArmorBonusRank2",
            "OnPotionArmorBonusRank3",
        ];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_2_Royal_Sword_Stance_HP") {
        unique.perk.statusLevels = ["SwordRoyalHealthBuffEffect"];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_4_Conduit") {
        unique.perk.statusLevels = [
            "ConduitEffectRank1",
            "ConduitEffectRank2",
            "ConduitEffectRank3",
        ];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_4_Augment") {
        unique.perk.statusLevels = [
            "AugmentRank1",
            "AugmentRank2",
            "AugmentRank3",
        ];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_3_Bow_RainArrowsReduceDmg") {
        unique.perk.statusLevels = [
            "RainArrowsReduceDmgRank1",
            "RainArrowsReduceDmgRank2",
            "RainArrowsReduceDmgRank3",
        ];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "OLDPerk_4_ColdBlooded") {
        unique.perk.statusLevels = [
            "ColdBloodedRank1",
            "ColdBloodedRank2",
            "ColdBloodedRank3",
        ];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_3_Lightning_OnPotionCritBuff") {
        unique.perk.statusLevels = [
            "OnPotionCritBonusRank1",
            "OnPotionCritBonusRank2",
            "OnPotionCritBonusRank3",
        ];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_4_Dervish") {
        unique.perk.statusLevels = [
            "DervishRank1",
            "DervishRank2",
            "DervishRank3",
        ];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_3_Axe_Holy_Inspire_Armor") {
        unique.perk.statusLevels = [
            "StatusEffect_Perk_3_Axe_Holy_Inspire_Armor1",
            "StatusEffect_Perk_3_Axe_Holy_Inspire_Armor2",
            "StatusEffect_Perk_3_Axe_Holy_Inspire_Armor3",
        ];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_4_Axe_Holy_ArmorShards2") {
        unique.perk.statusLevels = [
            "StatusEffect_Perk_4_Axe_Holy_ArmorShardRank1",
            "StatusEffect_Perk_4_Axe_Holy_ArmorShardRank1",
            "StatusEffect_Perk_4_Axe_Holy_ArmorShardRank1",
        ];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_4_Axe_Dark_WhirlLifesteal") {
        unique.perk.statusLevels = ["VampiricEffect"];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_5_Bow_Lightning_AutoShockPower") {
        unique.perk.statusLevels = ["BowLightningShockStacks"];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_5_Axe_Dark_OnPotionGiant") {
        unique.perk.statusLevels = ["OnPotionGiantSize"];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (
        unique.perk.name == "Perk_5_Axe_Holy_InspirePermanentCritDamage"
    ) {
        unique.perk.statusLevels = [
            "StatusEffect_Perk_5_InspirePermanentCritDamage",
        ];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_3_Fire_OnPotionDamageBuff") {
        unique.perk.statusLevels = [
            "OnPotionDamageBonusRank1",
            "OnPotionDamageBonusRank2",
            "OnPotionDamageBonusRank3",
        ];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_4_Sword_Dark_PhaseBolts") {
        unique.perk.statusLevels = ["CrippleEffect"];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_4_OnPotionDamageBuff") {
        unique.perk.statusLevels = [
            "OnPotionDamageBonusRank1",
            "OnPotionDamageBonusRank2",
            "OnPotionDamageBonusRank3",
        ];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    } else if (unique.perk.name == "Perk_3_Bow_RainArrowsCritChance") {
        unique.perk.statusLevels = [
            "RainArrowsCritChanceRank1",
            "RainArrowsCritChanceRank2",
            "RainArrowsCritChanceRank3",
        ];
        let classed = effectClassifier(
            unique.perk.statusLevels,
            statusEffects,
            unique
        );

        unique.classifier.push(classed?.split(/[-_]+/)[0]);
        unique.effectClass = classed;
    }
}
