import Filter from "./Filter";
import { convertNumToRarity, effectClassifier, perkClassifier } from "./utils";
import { exception } from "./exceptions";
import { returndiv, testcooldown } from "./cssutils";

import "./Perks.css";

const { innerWidth: screenWidth, innerHeight: screenHeight } = window;

export function createPerkDiv(data, divChoice) {
    var largediv = document.createElement("div");
    for (var i = 0; i < data.length; i++) {
        var div = document.createElement("div");

        let selectedDiv;
        let testdiv;
        let category;

        // let testDiv = document.getElementById(divChoice);

        // console.log(divChoice);
        //set specific div to place certain category of weapons
        if (data[i].perk.name.includes("Axe")) {
            selectedDiv = document.getElementById(divChoice + "Axes");
            category = "axe";
        } else if (data[i].perk.name.includes("Bow")) {
            selectedDiv = document.getElementById(divChoice + "Bows");
            category = "bow";
        } else if (data[i].perk.name.includes("Sword")) {
            selectedDiv = document.getElementById(divChoice + "Swords");
            category = "sword";
        } else if (data[i].perk.name.includes("Staff")) {
            // console.log(data[i].perk.name);
            selectedDiv = document.getElementById(divChoice + "Staves");
            category = "staff";
        } else {
            selectedDiv = document.getElementById(divChoice + "General");
            category = "general";
        }
        // console.log(selectedDiv);
        let increaseDurationIcon = require(`./assets/Combined/perk_uncommon_cooldown_reduction.png`);
        let increaseDMGIcon = require(`./assets/Combined/perk_common_extra_damage.png`);
        let sphereIcon = require(`./assets/staticons/icon_perk_glyph_attackrange.png`);
        let speedIcon = require(`./assets/staticons/icon_perk_glyph_runspeed.png`);
        let critIcon = require(`./assets/Combined/perk_common_chance_to_crit.png`);
        let attackspeedIcon = require(`./assets/Combined/perk_uncommon_knocked_loaded.png`);
        let armorIcon = require(`./assets/Combined/perk_common_chance_to_block.png`);
        let healIcon = require(`./assets/Combined/perk_common_extra_health.png`);
        let bleedIcon = require(`./assets/statuseffecticons/icon_status_effect_bleed.png`);
        let fireIcon = require(`./assets/statuseffecticons/icon_status_effect_fire.png`);
        let staticIcon = require(`./assets/statuseffecticons/icon_status_effect_shock.png`);
        let markIcon = require(`./assets/statuseffecticons/icon_status_effect_marked.png`);
        let curseIcon = require(`./assets/statuseffecticons/icon_status_effect_shadow.png`);
        let chillIcon = require(`./assets/statuseffecticons/icon_status_effect_ice.png`);
        let inspireIcon = require(`./assets/statuseffecticons/icon_status_effect_holy_light.png`);
        let reflectIcon = require(`./assets/Combined/perk_uncommon_dark_defense.png`);
        let critDmgIcon = require(`./assets/Combined/perk_common_extra_crit_damage.png`);
        let chanceIcon = require(`./assets/Combined/perk_epic_lightning_reflexes.png`);
        let resolveIcon = require(`./assets/Combined/perk_rare_sacred_concoction.png`);
        let imgSrc = require(`./assets/Combined/${data[i].perk.iconRef}.png`);
        let borderSrc = require(`./assets/Perks/ui/icon_perk_${convertNumToRarity(
            data[i].perk.rarity
        )}.png`);

        let dmgText = "";
        // if (
        //     data[i].perk.name == "Perk_2_Melee_Projectile" ||
        //     data[i].perk.name == "Perk_2_Sword_Knives" ||
        //     data[i].perk.name == "Perk_2_Bow_ProjectilesSplit" ||
        //     data[i].perk.name == "Perk_2_Bow_OpeningShot" ||
        //     data[i].perk.name == "Perk_2_Bow_RollLeavesTrap" ||
        //     data[i].perk.name == "Perk_4_Dark_CurseDoT" ||
        //     data[i].perk.name == "Perk_2_Axe_Spin" ||
        //     data[i].perk.name == "Perk_3_Fire_Axe_Tornado" ||
        //     data[i].perk.name == "Perk_4_PulsingFire" ||
        //     data[i].perk.name == "Perk_5_Generic_Axe_WhirlwindExplode" ||
        //     data[i].perk.name == "Perk_4_Firewaves" ||
        //     data[i].perk.name == "Perk_4_Axe_Holy_Spin" ||
        //     data[i].perk.name == "Perk_4_Axe_Ice_WhirlingShards" ||
        //     data[i].perk.name == "Perk_3_Ice_RollLeavesIce" ||
        //     data[i].perk.name == "Perk_5_Axe_Ice_Boomerang" ||
        //     data[i].perk.name == "Perk_4_Axe_Ice_Spin" ||
        //     data[i].perk.name == "Perk_5_Axe_Ice_FrostAura" ||
        //     data[i].perk.name == "Perk_3_Lightning_Axe_Strikes" ||
        //     data[i].perk.name == "Perk_4_OdinsFury"
        // ) {
        //     dmgText = "Percentage of base damage dealt";
        // } else {
        //     dmgText = "Increases damage of base attacks";
        // }

        if (data[i].perk.name.includes("RollLeaves")) {
            dmgText = "Percent of base Power damage dealt";
        } else {
            if (data[i].catState == "buff") {
                dmgText = "Increases damage of base attacks";
            } else {
                dmgText = "Percent of base Power damage dealt";
            }
        }

        let display3ranks = false;
        if (
            data[i].perk.maxAllowed == 3 ||
            (data[i].effectClass && data[i].effectClass != "undefined") ||
            data[i].classifier.includes("increase_duration") ||
            data[i].perk.type == "OrbitalPerkSO" ||
            data[i].classifier?.[0]?.split(/[-_]+/).length > 2
        ) {
            if (
                data[i].perk.maxAllowed == 3 ||
                data[i].perk.type == "OrbitalPerkSO" ||
                data[i].classifier.includes("increase_duration") ||
                data[i].classifier?.[0]?.split(/[-_]+/).length > 2 ||
                data[i].effectClass.split(/[-_]+/)[2]
            ) {
                display3ranks = true;
            }
        }

        // console.log(data[i].classifier?.[0]?.includes("damage_multi"));
        div.innerHTML =
            (screenWidth < 500
                ? "<div style=' display: flex; flex-direction: column; align-items: flex-start; margin-bottom: 20vw;'>"
                : "") +
            "<div class='mobilemarginzero' style='margin-bottom: 50px; display: flex; flex-direction: row; align-items: center; justify-content: flex-start'>" +
            `<div style="border-radius: 100px; height: 30px; width: 30px; margin-right: 15px"><h1 style='color:#B7EFED; font-size: 18px; text-align: center'>Lv. ${data[i].level}</h1></div>` +
            "<div style='position: relative; top: 0px; left:0px;  '>" +
            `<img class='iconborder' style='position: relative; top: 0px; left: 0px; width: ${
                screenWidth / 11
            }px; height: ${screenWidth / 11}px ' src=` +
            borderSrc +
            ">" +
            `<img class='iconimg' style='position: absolute; top: 0px; left: 0px;width: ${
                screenWidth / 11
            }px; height: ${screenWidth / 11}px' src=` +
            imgSrc +
            ">" +
            "</div>" +
            `<div class='namedesc' style='display: flex; flex-direction: column; padding-left: 20px; margin-top: 10px; width:${
                screenWidth / 3.56
            }px; padding-right: 10px '>` +
            "<h3 style='margin: 0; font-size: 23px; padding-bottom: 15px'>" +
            data[i].locs.name["English (United States)"] +
            "<p style='font-size: 15px'>(" +
            data[i].perk.name +
            ")</p>" +
            "</h3>" +
            "<p style='font-size: 17px; line-height: 25px'>" +
            //Adding description to the perk based on name, according to ./utils.js
            data[i].locs.description["English (United States)"] +
            " " +
            "</p>" +
            "</div>" +
            (screenWidth < 500 ? "</div>" : "") +
            "<div class='firstrow' style='display: flex; flex-direction: row; justify-content: center; padding-right: 100px'>" +
            (data[i].flag?.includes("flagged") ? "<h1>FLAGGED</h1>" : "") +
            //First Row
            (data[i].classifier.length == 0 &&
            data[i].extra == undefined &&
            data[i].effectClass == undefined &&
            data[i].perk.percent == undefined &&
            data[i].perk.name != "Perk_2_Bow_Puncture" &&
            data[i].perk.name != "Perk_2_Staff_Puncture"
                ? ""
                : "<div class='ateveryrank' style='display: flex; flex-direction: column; justify-content: center; width: 80px; margin-left: 20px'>" +
                  (data[i].perk.rarity == 1
                      ? "<p style='font-size: 16px; margin-left: 0px;text-align: center;  color: #BFD6EE'>At Every Rank:</p>"
                      : "<p style='font-size: 16px; margin-left: 0px;  color: #BFD6EE'>At Rank 1:</p>" +
                        //   ((data[i].effectClass &&
                        //       data[i].effectClass != "undefined") ||
                        //   data[i].perk.type == "OrbitalPerkSO"
                        //       ? data[i].perk.type == "OrbitalPerkSO" ||
                        //         data[i].effectClass.split(/[-_]+/)[2]
                        (display3ranks == true
                            ? "<p style='font-size: 16px; margin-left: 0px; margin-top: 50px; margin-bottom: 50px; color: #BFD6EE'>At Rank 2: </p>" +
                              "<p style='font-size: 16px; margin-left: 0px; color: #BFD6EE'>At Rank 3: </p>"
                            : "")) +
                  "</div>") +
            (isNaN(data[i].perk.percent * 100)
                ? ""
                : data[i].perk.maxAllowed == 0 || data[i].perk.maxAllowed == 1
                ? "<div class='statbox'><img style='width: 64px; height: 64px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'>" +
                  data[i].perk.percent * 100 +
                  "%</p></div>"
                : "") +
            //new row
            (data[i].classifier?.[0]?.includes("elementsphere")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' height: 64px'>" +
                  "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  "2</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Increase Projectiles</h1></div></div>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' height: 64px; margin-top: 20px; margin-bottom: 20px'>" +
                  "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  "4</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Increase Projectiles</h1></div></div>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' height: 64px; '>" +
                  "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  "6</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Increase Projectiles</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row
            //new row
            (data[i].extra?.includes("shards")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' height: 64px'>" +
                  "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  "1</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Increase Stack of Shards</h1></div></div>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' height: 64px; margin-top: 20px; margin-bottom: 20px'>" +
                  "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  "2</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Increase Stack of Shards</h1></div></div>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' height: 64px; '>" +
                  "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  "3</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Increase Stack of Shards</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row
            (data[i].classifier?.[0]?.includes("elementsphere") &&
            data[i].classifier?.[0] != "elementspherereflect"
                ? "<div class='statbox'>" +
                  "<img style='width: 64px; height: 64px' src=" +
                  increaseDMGIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  data[i].classifier[0].split(/[_]+/).slice(-1)[0] * 100 +
                  "%</p></div>"
                : "") +
            //Second Row
            //Second Row
            (data[i].classifier?.[0]?.includes("elementspherereflect")
                ? "<div class='statbox'>" +
                  "<img style='width: 64px; height: 64px' src=" +
                  reflectIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  "Reflect" +
                  "<br>Projectiles" +
                  "</p></div>"
                : "") +
            //Second Row
            (data[i].classifier?.[1]?.includes("radius") ||
            data[i].extra?.[0]?.includes("radius")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                  "<img style='width: 64px; height: 64px' src=" +
                  sphereIcon +
                  ">" +
                  "<p class='statboxtext'>" +
                  (data[i].classifier?.[1]?.includes("radius")
                      ? parseFloat(
                            data[i].classifier[1].split(/[-_]+/).slice(-1)[0]
                        )
                      : data[i].extra[0].split(/[-_]+/)[1]) +
                  "</p></div><div class='popover__content'>" +
                  `<h1 class='popover__message' >Ability Radius</h1></div></div>` +
                  (data[i].perk.maxAllowed > 1
                      ? "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                        "<img style='width: 64px; height: 64px' src=" +
                        sphereIcon +
                        ">" +
                        "<p class='statboxtext'>" +
                        (data[i].classifier?.[1]?.includes("radius")
                            ? parseFloat(
                                  data[i].classifier[1]
                                      .split(/[-_]+/)
                                      .slice(-1)[0]
                              )
                            : data[i].extra[0].split(/[-_]+/)[2]) +
                        "</p></div><div class='popover__content'>" +
                        `<h1 class='popover__message' >Ability Radius</h1></div></div>` +
                        "<div class='popover__wrapper'><a ><div class='statbox popover__title' >" +
                        "<img style='width: 64px; height: 64px' src=" +
                        sphereIcon +
                        ">" +
                        "<p class='statboxtext'>" +
                        (data[i].classifier?.[1]?.includes("radius")
                            ? parseFloat(
                                  data[i].classifier[1]
                                      .split(/[-_]+/)
                                      .slice(-1)[0]
                              )
                            : data[i].extra[0].split(/[-_]+/)[3]) +
                        "</p></div><div class='popover__content'>" +
                        `<h1 class='popover__message' >Ability Radius</h1></div></div>`
                      : "") +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            (data[i].classifier?.[1]?.includes("maxstacks")
                ? "<div class='statbox'>" +
                  "<img style='width: 64px; height: 64px' src=" +
                  sphereIcon +
                  ">" +
                  "<p class='statboxtext'>" +
                  parseFloat(
                      data[i].classifier[1].split(/[-_]+/).slice(-1)[0]
                  ) +
                  "</p></div>"
                : "") +
            //Second Row
            (data[i].effectClass &&
            data[i].effectClass != "undefined" &&
            data[i].effectClass.includes("special")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                  "<img style='width: 64px; height: 64px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  parseFloat(data[i].effectClass.split(/[-_]+/)[1]) * 100 +
                  "%</p></div></a> <div class='popover__content'>" +
                  `<h1 class='popover__message' >${dmgText}</h1></div></div>` +
                  (data[i].effectClass.split(/[_]+/)[2]
                      ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                        "<img style='width: 64px; height: 64px' src=" +
                        imgSrc +
                        ">" +
                        "<p class='statboxtext'> " +
                        Number(
                            (
                                parseFloat(
                                    data[i].effectClass.split(/[-_]+/)[2]
                                ) * 100
                            ).toFixed(2)
                        ) +
                        "%</p></div></a> <div class='popover__content'>" +
                        `<h1 class='popover__message' >${dmgText}</h1></div></div>`
                      : "") +
                  (data[i].effectClass.split(/[_]+/)[3]
                      ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                        "<img style='width: 64px; height: 64px' src=" +
                        imgSrc +
                        ">" +
                        "<p class='statboxtext'>" +
                        parseFloat(data[i].effectClass.split(/[-_]+/)[3]) *
                            100 +
                        "%</p></div></a> <div class='popover__content'>" +
                        `<h1 class='popover__message' >${dmgText}</h1></div></div>`
                      : "") +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            (data[i].effectClass &&
            data[i].effectClass != "undefined" &&
            data[i].effectClass.includes("cooldownAccelerationBuff")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                  "<img style='width: 64px; height: 64px' src=" +
                  attackspeedIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  parseFloat(data[i].effectClass.split(/[-_]+/)[1]) * 100 +
                  "%</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Increases Attack Speed</h1></div></div>" +
                  (data[i].effectClass.split(/[_]+/)[2]
                      ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                        "<img style='width: 64px; height: 64px' src=" +
                        attackspeedIcon +
                        ">" +
                        "<p class='statboxtext'> " +
                        Number(
                            (
                                parseFloat(
                                    data[i].effectClass.split(/[-_]+/)[2]
                                ) * 100
                            ).toFixed(2)
                        ) +
                        "%</p></div></a> <div class='popover__content'>" +
                        "<h1 class='popover__message' >Increases Attack Speed</h1></div></div>"
                      : "") +
                  (data[i].effectClass.split(/[_]+/)[3]
                      ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                        "<img style='width: 64px; height: 64px' src=" +
                        attackspeedIcon +
                        ">" +
                        "<p class='statboxtext'>" +
                        parseFloat(data[i].effectClass.split(/[-_]+/)[3]) *
                            100 +
                        "%</p></div></a> <div class='popover__content'>" +
                        "<h1 class='popover__message' >Increases Attack Speed</h1></div></div>"
                      : "") +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("chance")) ||
            data[i].classifier?.[0]?.includes("chance") ||
            data[i].anotherRankClassifier?.[0]?.includes("chance") ||
            data[i].testRankClassifier?.[0]?.includes("chance") ||
            data[i].extra?.includes("chance")
                ? testcooldown(
                      data[i].effectClass,
                      "chance",
                      "Chance to apply",
                      chanceIcon,
                      data[i].anotherRankClassifier,
                      data[i].testRankClassifier,
                      "%",
                      100,
                      data[i].extra
                  )
                : // ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  //   "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                  //   "<img style='width: 64px; height: 64px' src=" +
                  //   chanceIcon +
                  //   ">" +
                  //   "<p class='statboxtext'> " +
                  //   parseFloat(data[i].effectClass.split(/[-_]+/)[1]) * 100 +
                  //   "%</p></div></a> <div class='popover__content'>" +
                  //   "<h1 class='popover__message' >Chance to apply Effect</h1></div></div>" +
                  //   (data[i].effectClass.split(/[_]+/)[2]
                  //       ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                  //         "<img style='width: 64px; height: 64px' src=" +
                  //         chanceIcon +
                  //         ">" +
                  //         "<p class='statboxtext'> " +
                  //         Number(
                  //             (
                  //                 parseFloat(
                  //                     data[i].effectClass.split(/[-_]+/)[2]
                  //                 ) * 100
                  //             ).toFixed(2)
                  //         ) +
                  //         "%</p></div></a> <div class='popover__content'>" +
                  //         "<h1 class='popover__message' >Chance to apply Effect</h1></div></div>"
                  //       : "") +
                  //   (data[i].effectClass.split(/[_]+/)[3]
                  //       ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                  //         "<img style='width: 64px; height: 64px' src=" +
                  //         chanceIcon +
                  //         ">" +
                  //         "<p class='statboxtext'>" +
                  //         parseFloat(data[i].effectClass.split(/[-_]+/)[3]) *
                  //             100 +
                  //         "%</p></div></a> <div class='popover__content'>" +
                  //         "<h1 class='popover__message' >Chance to apply Effect</h1></div></div>"
                  //       : "") +
                  //   "</div>"
                  "") +
            //Second Row
            //Second Row
            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("hpMultiplier")) ||
            data[i].classifier?.[0]?.includes("hpMultiplier") ||
            data[i].anotherRankClassifier?.[0]?.includes("hpMultiplier") ||
            data[i].testRankClassifier?.[0]?.includes("hpMultiplier") ||
            data[i].extra?.includes("hpMultiplier")
                ? testcooldown(
                      data[i].effectClass,
                      "hpMultiplier",
                      "Entity Health",
                      healIcon,
                      data[i].anotherRankClassifier,
                      data[i].testRankClassifier,
                      "%",
                      100,
                      data[i].extra
                  )
                : "") +
            //Second Row
            //Second Row
            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("tickIntervalOverride")) ||
            data[i].classifier?.[0]?.includes("tickIntervalOverride") ||
            data[i].anotherRankClassifier?.[0]?.includes(
                "tickIntervalOverride"
            ) ||
            data[i].testRankClassifier?.[0]?.includes("tickIntervalOverride") ||
            data[i].extra?.includes("tickIntervalOverride")
                ? testcooldown(
                      data[i].effectClass,
                      "tickIntervalOverride",
                      "Pulsing Interval",
                      imgSrc,
                      data[i].anotherRankClassifier,
                      data[i].testRankClassifier,
                      "s",
                      1,
                      data[i].extra
                  )
                : "") +
            //Second Row
            //Second Row
            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("blockBuff")) ||
            data[i].classifier?.[0]?.includes("blockBuff") ||
            data[i].anotherRankClassifier?.[0]?.includes("blockBuff") ||
            data[i].testRankClassifier?.[0]?.includes("blockBuff") ||
            data[i].extra?.includes("blockBuff")
                ? testcooldown(
                      data[i].effectClass,
                      "blockBuff",
                      "Block Buff",
                      imgSrc,
                      data[i].anotherRankClassifier,
                      data[i].testRankClassifier,
                      "%",
                      100,
                      data[i].extra
                  )
                : "") +
            //Second Row
            //Second Row
            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("speedBuff")) ||
            data[i].classifier?.[0]?.includes("speedBuff") ||
            data[i].anotherRankClassifier?.[0]?.includes("speedBuff") ||
            data[i].testRankClassifier?.[0]?.includes("speedBuff") ||
            data[i].extra?.includes("speedBuff")
                ? testcooldown(
                      data[i].effectClass,
                      "speedBuff",
                      data[i].anotherRankClassifier?.[1] < 0
                          ? "Slow Enemies"
                          : "Increase Movement Speed",
                      speedIcon,
                      data[i].anotherRankClassifier,
                      false,
                      "%",
                      100,
                      data[i].extra,
                      44,
                      "",
                      true
                  )
                : "") +
            //Second Row
            //Second Row
            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("applyholylight")) ||
            data[i].classifier?.[0]?.includes("applyholylight") ||
            data[i].anotherRankClassifier?.[0]?.includes("applyholylight") ||
            data[i].testRankClassifier?.[0]?.includes("applyholylight") ||
            data[i].extra?.includes("applyholylight")
                ? testcooldown(
                      data[i].effectClass,
                      "applyholylight",
                      "Apply Holy Light Stacks",
                      imgSrc,
                      data[i].anotherRankClassifier,
                      false,
                      "",
                      1,
                      false,
                      64,
                      "",
                      false
                  )
                : "") +
            //Second Row
            //Second Row
            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("triggerCount")) ||
            data[i].classifier?.[0]?.includes("triggerCount") ||
            data[i].anotherRankClassifier?.[0]?.includes("triggerCount") ||
            data[i].testRankClassifier?.[0]?.includes("triggerCount") ||
            data[i].extra?.includes("triggerCount")
                ? testcooldown(
                      data[i].effectClass,
                      "triggerCount",
                      "Increases Duration",
                      imgSrc,
                      data[i].anotherRankClassifier,
                      data[i].testRankClassifier,
                      "s",
                      1,
                      false,
                      64,
                      "",
                      false
                  )
                : "") +
            //Second Row
            //Second Row
            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("hptodmg")) ||
            data[i].classifier?.[0]?.includes("hptodmg") ||
            data[i].anotherRankClassifier?.[0]?.includes("hptodmg") ||
            data[i].testRankClassifier?.[0]?.includes("hptodmg") ||
            data[i].extra?.includes("hptodmg")
                ? testcooldown(
                      data[i].effectClass,
                      "hptodmg",
                      "Increase DMG by %hp",
                      imgSrc,
                      data[i].anotherRankClassifier,
                      data[i].testRankClassifier,
                      "%",
                      100,
                      false,
                      64,
                      "",
                      false
                  )
                : "") +
            //Second Row
            //Second Row
            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("vortex")) ||
            data[i].classifier?.[0]?.includes("vortex") ||
            data[i].anotherRankClassifier?.[0]?.includes("vortex") ||
            data[i].testRankClassifier?.[0]?.includes("vortex") ||
            data[i].extra?.includes("vortex")
                ? testcooldown(
                      data[i].effectClass,
                      "vortex",
                      "Vortex Effect Strength",
                      imgSrc,
                      data[i].anotherRankClassifier,
                      data[i].testRankClassifier,
                      "%",
                      100,
                      false,
                      64,
                      "",
                      false
                  )
                : "") +
            //Second Row
            //Second Row
            (data[i].classifier?.[1] &&
            data[i].classifier?.[1] != "undefined" &&
            data[i].classifier?.[1].includes("bighit")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  "(On Hit)</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Apply Big Hit On Hit</h1></div></div>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px;margin-top: 20px; margin-bottom: 20px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  "(On Hit)</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Apply Big Hit On Hit</h1></div></div>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  "(On Hit)</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Apply Big Hit On Hit</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            (data[i].apply &&
            data[i].apply != "undefined" &&
            data[i].apply.includes("ondefeat")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  `(On Defeat)</p></div></a> <div class='popover__content'>` +
                  "<h1 class='popover__message' >Apply On Defeat</h1></div></div>" +
                  (data[i].perk.maxAllowed > 1
                      ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px;width: 150px; height: 64px'>" +
                        "<img style='width: 44px; height: 44px' src=" +
                        imgSrc +
                        ">" +
                        "<p class='statboxtext'> " +
                        `(On Defeat)</p></div></a> <div class='popover__content'>` +
                        "<h1 class='popover__message' >Apply On Defeat</h1></div></div>" +
                        "<div class='popover__wrapper' ><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                        "<img style='width: 44px; height: 44px' src=" +
                        imgSrc +
                        ">" +
                        "<p class='statboxtext'>" +
                        `(On Defeat)</p></div></a> <div class='popover__content'>` +
                        "<h1 class='popover__message' >Apply On Defeat</h1></div></div>"
                      : "") +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            (data[i].perk.name.includes("Perk_2_Bow_Puncture")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  `+Piercing</p></div></a> <div class='popover__content'>` +
                  "<h1 class='popover__message' >Add Piercing Effect</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            (data[i].perk.name.includes("Perk_2_Staff_Puncture")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  `+Piercing</p></div></a> <div class='popover__content'>` +
                  "<h1 class='popover__message' >Add Piercing Effect</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            (data[i].classifier &&
            data[i].classifier != "undefined" &&
            data[i].classifier.includes("applybleed")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  bleedIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  `(On ${
                      data[i].perk.name == "Perk_2_Axe_CritAppliesBleed"
                          ? "Crit"
                          : "Hit"
                  })</p></div></a> <div class='popover__content'>` +
                  `<h1 class='popover__message' >Apply Wound On ${
                      data[i].perk.name == "Perk_2_Axe_CritAppliesBleed"
                          ? "Crit"
                          : "Hit"
                  }</h1></div></div>` +
                  (data[i].effectClass
                      ? (data[i].effectClass.split(/[_]+/)[2]
                            ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px;width: 150px; height: 64px'>" +
                              "<img style='width: 44px; height: 44px' src=" +
                              bleedIcon +
                              ">" +
                              "<p class='statboxtext'> " +
                              `(On ${
                                  data[i].perk.name ==
                                  "Perk_2_Axe_CritAppliesBleed"
                                      ? "Crit"
                                      : "Hit"
                              })</p></div></a> <div class='popover__content'>` +
                              "<h1 class='popover__message' >Apply Wound on Hit</h1></div></div>"
                            : "") +
                        (data[i].effectClass.split(/[_]+/)[3]
                            ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                              "<img style='width: 44px; height: 44px' src=" +
                              bleedIcon +
                              ">" +
                              "<p class='statboxtext'>" +
                              `(On ${
                                  data[i].perk.name ==
                                  "Perk_2_Axe_CritAppliesBleed"
                                      ? "Crit"
                                      : "Hit"
                              })</p></div></a> <div class='popover__content'>` +
                              "<h1 class='popover__message' >Apply Wound On Hit</h1></div></div>"
                            : "")
                      : "") +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            //Second Row
            (data[i].classifier &&
            data[i].classifier != "undefined" &&
            data[i].classifier.includes("applystaticoncrit")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  staticIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  "(On Crit)</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Applies Static</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row

            //Second Row
            (data[i].classifier &&
            data[i].classifier != "undefined" &&
            data[i].classifier.includes("applyresolve")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  resolveIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  "(On Use)</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Applies Resolve</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            (data[i].classifier &&
            data[i].classifier != "undefined" &&
            data[i].classifier.includes("applyinspireoncrit")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  inspireIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  "(On Crit)</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Applies Inspire</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            (data[i].classifier &&
            data[i].classifier != "undefined" &&
            data[i].classifier.includes("applyburnoncrit")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  fireIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  "(On Crit)</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Applies Burn</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            (data[i].classifier &&
            data[i].classifier != "undefined" &&
            data[i].classifier.includes("applycurseoncrit")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  curseIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  "(On Crit)</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Applies Curse</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            (data[i].classifier &&
            data[i].classifier != "undefined" &&
            data[i].classifier.includes("applychilloncrit")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  chillIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  "(On Crit)</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Applies Chill</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            (data[i].classifier &&
            data[i].classifier != "undefined" &&
            data[i].classifier.includes("applymarkoncrit")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                  "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                  markIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  "(On Crit)</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Applies Mark</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row
            //Second Row
            returndiv(
                data[i].classifier,
                "applyburn",
                "Applies Burn",
                fireIcon,
                3
            ) +
            returndiv(
                data[i].classifier,
                "applystatic",
                "Applies Static",
                staticIcon,
                3
            ) +
            returndiv(
                data[i].classifier,
                "applymark",
                "Applies Mark",
                markIcon,
                3
            ) +
            returndiv(
                data[i].classifier,
                "applycurse",
                "Applies Curse",
                curseIcon,
                3
            ) +
            returndiv(
                data[i].classifier,
                "applychill",
                "Applies Chill",
                chillIcon,
                3
            ) +
            returndiv(
                data[i].classifier,
                "applyinspire",
                "Applies Inspire",
                inspireIcon,
                3
            ) +
            //Second Row
            (data[i].effectClass &&
            data[i].effectClass != "undefined" &&
            data[i].effectClass.includes("reflectBuff")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                  "<img style='width: 64px; height: 64px' src=" +
                  reflectIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  parseFloat(data[i].effectClass.split(/[-_]+/)[1]) * 100 +
                  "%</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Damage buff on reflection</h1></div></div>" +
                  (data[i].effectClass.split(/[_]+/)[2]
                      ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                        "<img style='width: 64px; height: 64px' src=" +
                        reflectIcon +
                        ">" +
                        "<p class='statboxtext'> " +
                        Number(
                            (
                                parseFloat(
                                    data[i].effectClass.split(/[-_]+/)[2]
                                ) * 100
                            ).toFixed(2)
                        ) +
                        "%</p></div></a> <div class='popover__content'>" +
                        "<h1 class='popover__message' >Damage buff on reflection</h1></div></div>"
                      : "") +
                  (data[i].effectClass.split(/[_]+/)[3]
                      ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                        "<img style='width: 64px; height: 64px' src=" +
                        reflectIcon +
                        ">" +
                        "<p class='statboxtext'>" +
                        parseFloat(data[i].effectClass.split(/[-_]+/)[3]) *
                            100 +
                        "%</p></div></a> <div class='popover__content'>" +
                        "<h1 class='popover__message' >Damage buff on reflection</h1></div></div>"
                      : "") +
                  "</div>"
                : "") +
            //First Row
            (data[i].classifier?.[0]?.includes("damagemulti")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  (data[i].classifier[0].split(/[_]+/)[1] ==
                      data[i].classifier[0].split(/[_]+/)[2] &&
                  data[i].classifier[0].split(/[_]+/)[1] ==
                      data[i].classifier[0].split(/[_]+/)[3]
                      ? "<h1>FLAGGED</h1>"
                      : "") +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                  "<img style='width: 64px; height: 64px' src=" +
                  (parseFloat(data[i].classifier[0].split(/[_]+/)[1]) < 0
                      ? healIcon
                      : increaseDMGIcon) +
                  ">" +
                  "<p class='statboxtext'> " +
                  Number(
                      (
                          parseFloat(data[i].classifier[0].split(/[-_]+/)[1]) *
                          100
                      ).toFixed(2)
                  ) +
                  "%</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >" +
                  (parseFloat(data[i].classifier[0].split(/[_]+/)[1]) < 0
                      ? "Percent of base Power healing done"
                      : `${dmgText}`) +
                  "</h1></div></div>" +
                  (data[i].classifier[0].split(/[_]+/)[2]
                      ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                        "<img style='width: 64px; height: 64px' src=" +
                        (parseFloat(data[i].classifier[0].split(/[_]+/)[2]) < 0
                            ? healIcon
                            : increaseDMGIcon) +
                        ">" +
                        "<p class='statboxtext'> " +
                        Number(
                            (
                                parseFloat(
                                    data[i].classifier[0].split(/[-_]+/)[2]
                                ) * 100
                            ).toFixed(2)
                        ) +
                        "%</p></div></a> <div class='popover__content'>" +
                        "<h1 class='popover__message' >" +
                        (parseFloat(data[i].classifier[0].split(/[_]+/)[2]) < 0
                            ? "Percent of base Power healing done"
                            : `${dmgText}`) +
                        "</h1></div></div>"
                      : "") +
                  (data[i].classifier[0].split(/[_]+/)[3]
                      ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                        "<img style='width: 64px; height: 64px' src=" +
                        (parseFloat(data[i].classifier[0].split(/[_]+/)[3]) < 0
                            ? healIcon
                            : increaseDMGIcon) +
                        ">" +
                        "<p class='statboxtext'>" +
                        Number(
                            (
                                parseFloat(
                                    data[i].classifier[0].split(/[-_]+/)[3]
                                ) * 100
                            ).toFixed(2)
                        ) +
                        "%</p></div></a> <div class='popover__content'>" +
                        "<h1 class='popover__message' >" +
                        (parseFloat(data[i].classifier[0].split(/[_]+/)[3]) < 0
                            ? "Percent of base Power healing done"
                            : `${dmgText}`) +
                        "</h1></div></div>"
                      : "") +
                  "</div>"
                : "") +
            //Second Row
            //First Row
            (data[i].effectClass &&
            data[i].effectClass != "undefined" &&
            data[i].classifier.includes("damagetest")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                  "<img style='width: 64px; height: 64px' src=" +
                  (parseFloat(data[i].effectClass.split(/[_]+/)[1]) < 0
                      ? healIcon
                      : increaseDMGIcon) +
                  ">" +
                  "<p class='statboxtext'> " +
                  parseFloat(data[i].effectClass.split(/[-_]+/)[1]) * 100 +
                  "%</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >" +
                  (parseFloat(data[i].effectClass.split(/[_]+/)[1]) < 0
                      ? "Percent of base Power healing done"
                      : data[i].perk.name == "Perk_2_Bow_Pet"
                      ? "Pet Damage"
                      : `${dmgText}`) +
                  "</h1></div></div>" +
                  (data[i].effectClass.split(/[_]+/)[2]
                      ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                        "<img style='width: 64px; height: 64px' src=" +
                        (parseFloat(data[i].effectClass.split(/[_]+/)[2]) < 0
                            ? healIcon
                            : increaseDMGIcon) +
                        ">" +
                        "<p class='statboxtext'> " +
                        Number(
                            (
                                parseFloat(
                                    data[i].effectClass.split(/[-_]+/)[2]
                                ) * 100
                            ).toFixed(2)
                        ) +
                        "%</p></div></a> <div class='popover__content'>" +
                        "<h1 class='popover__message' >" +
                        (parseFloat(data[i].effectClass.split(/[_]+/)[2]) < 0
                            ? "Percent of base Power healing done"
                            : `${dmgText}`) +
                        "</h1></div></div>"
                      : "") +
                  (data[i].effectClass.split(/[_]+/)[3]
                      ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                        "<img style='width: 64px; height: 64px' src=" +
                        (parseFloat(data[i].effectClass.split(/[_]+/)[3]) < 0
                            ? healIcon
                            : increaseDMGIcon) +
                        ">" +
                        "<p class='statboxtext'>" +
                        parseFloat(data[i].effectClass.split(/[-_]+/)[3]) *
                            100 +
                        "%</p></div></a> <div class='popover__content'>" +
                        "<h1 class='popover__message' >" +
                        (parseFloat(data[i].effectClass.split(/[_]+/)[1]) < 0
                            ? "Percent of base Power healing done"
                            : `${dmgText}`) +
                        "</h1></div></div>"
                      : "") +
                  "</div>"
                : "") +
            //Second Row
            //First Row
            (data[i].testRankClassifier &&
            data[i].testRankClassifier != "undefined" &&
            data[i].testRankClassifier[0] == "damagetestheal"
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                  "<img style='width: 64px; height: 64px' src=" +
                  (parseFloat(data[i].testRankClassifier[1]) < 0
                      ? healIcon
                      : increaseDMGIcon) +
                  ">" +
                  "<p class='statboxtext'> " +
                  Math.abs(parseFloat(data[i].testRankClassifier[1])) * 100 +
                  "%</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >" +
                  (parseFloat(data[i].testRankClassifier[1]) < 0
                      ? "Percent of base Power healing done"
                      : data[i].perk.name == "Perk_2_Bow_Pet"
                      ? "Pet Damage"
                      : `${dmgText}`) +
                  "</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row
            //First Row
            (data[i].extra &&
            data[i].extra != "undefined" &&
            data[i].extra[0].includes("damagemultiwhirl")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                  "<img style='width: 64px; height: 64px' src=" +
                  (parseFloat(data[i].extra[1]) < 0
                      ? healIcon
                      : increaseDMGIcon) +
                  ">" +
                  "<p class='statboxtext'> " +
                  Math.abs(parseFloat(data[i].extra[1])) * 100 +
                  "%</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >" +
                  (parseFloat(data[i].extra[1]) < 0
                      ? "Percent of base Power healing done"
                      : data[i].perk.name == "Perk_2_Bow_Pet"
                      ? "Pet Damage"
                      : `${dmgText}`) +
                  "</h1></div></div>" +
                  "</div>"
                : "") +
            //Second Row
            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("crithance")) ||
            data[i].classifier?.[0]?.includes("crithance") ||
            data[i].anotherRankClassifier?.[0]?.includes("crithance") ||
            data[i].testRankClassifier?.[0]?.includes("crithance") ||
            data[i].extra?.includes("crithance")
                ? testcooldown(
                      data[i].effectClass,
                      "crithance",
                      "Increase Crit Chance",
                      critIcon,
                      false,
                      data[i].testRankClassifier,
                      "%",
                      100,
                      data[i].extra
                  )
                : "") +
            //Second Row
            //Second Row
            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("maxHealthBuff")) ||
            data[i].classifier?.[0]?.includes("maxHealthBuff") ||
            data[i].anotherRankClassifier?.[0]?.includes("maxHealthBuff") ||
            data[i].testRankClassifier?.[0]?.includes("maxHealthBuff") ||
            data[i].extra?.includes("maxHealthBuff")
                ? testcooldown(
                      data[i].effectClass,
                      "maxHealthBuff",
                      "Increase Max Hp",
                      healIcon,
                      data[i].anotherRankClassifier,
                      data[i].testRankClassifier,
                      "%",
                      100,
                      data[i].extra
                  )
                : "") +
            //Second Row
            //Second Row
            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("critdmgmulti")) ||
            data[i].classifier?.[0]?.includes("critdmgmulti") ||
            data[i].anotherRankClassifier?.[0]?.includes("critdmgmulti") ||
            data[i].testRankClassifier?.[0]?.includes("critdmgmulti") ||
            data[i].extra?.includes("critdmgmulti")
                ? testcooldown(
                      data[i].effectClass,
                      "critdmgmulti",
                      "Crit Damage Increase",
                      critDmgIcon,
                      data[i].anotherRankClassifier,
                      data[i].testRankClassifier,
                      "%",
                      100,
                      data[i].extra
                  )
                : "") +
            //Second Row
            // //Second Row
            // (data[i].effectClass &&
            // data[i].classifier.includes("critdmgmulti")
            //     ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
            //       "<div  class='statbox'>" +
            //       "<img style='width: 64px; height: 64px' src=" +
            //       critDmgIcon +
            //       ">" +
            //       "<p class='statboxtext'> " +
            //       parseFloat(data[i].effectClass.split(/[-_]+/)[1]) * 100 +
            //       "%</p></div>" +
            //       (data[i].effectClass.split(/[-_]+/)[2]
            //           ? "<div  class='statbox' style='margin-top: 20px; margin-bottom: 20px'>" +
            //             "<img style='width: 64px; height: 64px' src=" +
            //             critDmgIcon +
            //             ">" +
            //             "<p class='statboxtext'> " +
            //             parseFloat(data[i].effectClass.split(/[-_]+/)[2]) *
            //                 100 +
            //             "%</p></div>"
            //           : "") +
            //       (data[i].effectClass.split(/[-_]+/)[3]
            //           ? "<div  class='statbox'>" +
            //             "<img style='width: 64px; height: 64px' src=" +
            //             critDmgIcon +
            //             ">" +
            //             "<p class='statboxtext'>" +
            //             parseFloat(data[i].effectClass.split(/[-_]+/)[3]) *
            //                 100 +
            //             "%</p></div>"
            //           : "") +
            //       "</div>"
            //     : "") +
            // //Second Row
            (data[i].classifier?.[0]?.includes("critdamage")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                  "<img style='width: 64px; height: 64px' src=" +
                  critIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  parseFloat(data[i].classifier?.[0]?.split(/[-_]+/)[1]) * 100 +
                  "%</p></div></a> <div class='popover__content'>" +
                  "<h1 class='popover__message' >Increase Crit Damage</h1></div></div>" +
                  (data[i].perk.maxAllowed > 2
                      ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                        "<img style='width: 64px; height: 64px' src=" +
                        critIcon +
                        ">" +
                        "<p class='statboxtext'> " +
                        parseFloat(data[i].classifier?.[0]?.split(/[-_]+/)[1]) *
                            100 *
                            2 +
                        "%</p></div></a> <div class='popover__content'>" +
                        "<h1 class='popover__message' >Increase Crit Damage</h1></div></div>"
                      : "") +
                  (data[i].perk.maxAllowed == 3
                      ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                        "<img style='width: 64px; height: 64px' src=" +
                        critIcon +
                        ">" +
                        "<p class='statboxtext'>" +
                        parseFloat(data[i].classifier?.[0]?.split(/[-_]+/)[1]) *
                            100 *
                            3 +
                        "%</p></div></a> <div class='popover__content'>" +
                        "<h1 class='popover__message' >Increase Crit Damage</h1></div></div>"
                      : "") +
                  "</div>"
                : "") +
            //First Row
            //Second Row

            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("cooldownreduce")) ||
            data[i].classifier?.[0]?.includes("cooldownreduce") ||
            data[i].anotherRankClassifier?.[0]?.includes("cooldownreduce") ||
            data[i].testRankClassifier?.[0]?.includes("cooldownreduce") ||
            data[i].extra?.includes("cooldownreduce")
                ? testcooldown(
                      data[i].effectClass,
                      "cooldownreduce",
                      "Reduce Cooldown",
                      increaseDurationIcon,
                      data[i].anotherRankClassifier,
                      data[i].testRankClassifier,
                      "s",
                      1,
                      null,
                      64,
                      data[i].perk.name == "Perk_3_TakeDamageDecreaseCD"
                          ? "(On Taking Hits)"
                          : null
                  )
                : "") +
            //First Row
            //Second Row
            (data[i].extra && data[i].extra.includes("distance")
                ? testcooldown(
                      data[i].extra,
                      "knockback",
                      "Knockback Strength",
                      imgSrc,
                      false,
                      false,
                      "%",
                      100
                  )
                : "") +
            //First Row
            ((data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].classifier.includes("armorbuff")) ||
            data[i].anotherRankClassifier?.[0]?.includes("armorbuff") ||
            data[i].testRankClassifier?.[0]?.includes("armorbuff")
                ? testcooldown(
                      data[i].effectClass,
                      "armorbuff",
                      "Increase Armor",
                      armorIcon,
                      data[i].anotherRankClassifier,
                      data[i].testRankClassifier,
                      "%",
                      100,
                      null,
                      64,
                      data[i].perk.name == "Perk_2_Axe_RollGivesArmor"
                          ? "(On Roll)"
                          : ""
                  )
                : "") +
            //Second Row
            (data[i].classifier.includes("increase_duration")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' >" +
                  "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                  increaseDurationIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  `${data[i].perk.additiveModification.increaseStatusDuration}s</p></div></a> <div class='popover__content'>` +
                  `<h1 class='popover__message' >Increase Duration of Effect</h1></div></div>` +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' margin-top: 20px; margin-bottom: 20px'>" +
                  "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                  increaseDurationIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  `${
                      data[i].perk.additiveModification.increaseStatusDuration +
                      2
                  }s</p></div></a> <div class='popover__content'>` +
                  `<h1 class='popover__message' >Increase Duration of Effect</h1></div></div>` +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' >" +
                  "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                  increaseDurationIcon +
                  ">" +
                  "<p class='statboxtext'> " +
                  `${
                      data[i].perk.additiveModification.increaseStatusDuration +
                      4
                  }s</p></div></a> <div class='popover__content'>` +
                  `<h1 class='popover__message' >Increase Duration of Effect</h1></div></div>` +
                  "</div>"
                : "") +
            //Second Row
            (data[i].classifier.includes("increaseabilityrange")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' >" +
                  "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  (data[i].perk.name == "Perk_4_Axe_Dark_Melee_Range" ||
                  data[i].perk.name == "Perk_3_Axe_Holy_Melee_Range"
                      ? `${data[i].perk?.modifyLevels?.[0]?.increaseAbilityRange}`
                      : `${data[i].perk?.additiveModification?.increaseAbilityRange}`) +
                  `</p></div></a> <div class='popover__content'>` +
                  `<h1 class='popover__message' >Increase Ability Range</h1></div></div>` +
                  (data[i].perk.name == "Perk_5_Axe_Holy_WhirlSize" ||
                  data[i].perk.name == "Perk_4_Axe_Dark_Melee_Range" ||
                  "Perk_3_Axe_Holy_Melee_Range"
                      ? ""
                      : "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' margin-top: 20px; margin-bottom: 20px'>" +
                        "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                        imgSrc +
                        ">" +
                        "<p class='statboxtext'> " +
                        `${
                            data[i].perk.additiveModification
                                .increaseAbilityRange + 1
                        }</p></div></a> <div class='popover__content'>` +
                        `<h1 class='popover__message' >Increase Ability Range</h1></div></div>` +
                        "<div class='popover__wrapper'><a ><div class='statbox popover__title' >" +
                        "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                        imgSrc +
                        ">" +
                        "<p class='statboxtext'> " +
                        `${
                            data[i].perk.additiveModification
                                .increaseAbilityRange + 2
                        }</p></div></a> <div class='popover__content'>` +
                        `<h1 class='popover__message' >Increase Ability Range</h1></div></div>` +
                        "</div>")
                : "") +
            //End Rows
            //Second Row
            (data[i].classifier.includes("increaseprojectilerange")
                ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                  "<div class='popover__wrapper'><a ><div class='statbox popover__title' >" +
                  "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                  imgSrc +
                  ">" +
                  "<p class='statboxtext'> " +
                  (data[i].perk.name == "Perk_4_Sword_Fire_LeapArea"
                      ? `${data[i].perk?.additiveModification?.increaseProjectileSize}`
                      : `${data[i].perk?.additiveModification?.increaseAbilityRange}`) +
                  `</p></div></a> <div class='popover__content'>` +
                  `<h1 class='popover__message' >Increase Ability Range</h1></div></div>` +
                  (data[i].perk.name == "Perk_5_Axe_Holy_WhirlSize" ||
                  data[i].perk.name == "Perk_4_Axe_Dark_Melee_Range"
                      ? ""
                      : "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' margin-top: 20px; margin-bottom: 20px'>" +
                        "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                        imgSrc +
                        ">" +
                        "<p class='statboxtext'> " +
                        `${
                            data[i].perk?.additiveModification
                                ?.increaseProjectileSize + 1
                        }</p></div></a> <div class='popover__content'>` +
                        `<h1 class='popover__message' >Increase Ability Range</h1></div></div>` +
                        "<div class='popover__wrapper'><a ><div class='statbox popover__title' >" +
                        "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                        imgSrc +
                        ">" +
                        "<p class='statboxtext'> " +
                        `${
                            data[i].perk?.additiveModification
                                ?.increaseProjectileSize + 2
                        }</p></div></a> <div class='popover__content'>` +
                        `<h1 class='popover__message' >Increase Ability Range</h1></div></div>` +
                        "</div>")
                : "") +
            //End Rows
            "</div>" +
            "</div>";
        // console.log(div);
        //test
        // console.log(divH)
        largediv.appendChild(div);
    }
    return largediv;
}
