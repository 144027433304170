import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
    useSearchParams,
    useNavigate,
} from "react-router-dom";

import "./Perks.css";

import { convertNumToRarity, effectClassifier, perkClassifier } from "./utils";
import { exception } from "./exceptions";
import { returndiv, testcooldown } from "./cssutils";

import { createPerkDiv } from "./createPerkDiv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faA, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import { filterPerks } from "./Weapons";
const { innerWidth: screenWidth, innerHeight: screenHeight } = window;

function Perks() {
    const location = useLocation();
    const data = location.state;
    let navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        // 👇️ call method in useEffect hook
        const el = document.getElementById("tester");

        let arrayOfLocs = data.arrayOfLocs;

        let thediv = filterPerks(
            data.currentWeapon,
            data.statusEffects,
            data.weaponName
        );

        let testData = data.statusEffects.filter((effect) => {
            return effect.name.includes(
                "StatusEffect_Perk_4_Bow_Holy_PassiveStacksGiveCritChance1"
            );
        });
        console.log(testData);

        function filterPerks(weapon, statusEffects, weaponName) {
            // console.log(rarity);

            // console.log(weaponsWithPerks);

            var perksWithRarity = [];
            var arrayOfPerks = [];
            var defaultPerks = [];

            //filtering for a specific rarity of perk

            // eachWeapon(weaponsWithPerks[i]);
            console.log(weapon);
            perksWithRarity = weapon.perks;
            defaultPerks = weapon.defaultPerks;
            console.log(perksWithRarity);
            console.log(defaultPerks);

            for (var perkD of defaultPerks) {
                arrayOfPerks.push({ level: 0, perk: perkD });
            }

            for (var perkI of perksWithRarity) {
                arrayOfPerks.push(perkI);
            }

            // console.log(arrayOfPerks);

            //Checks if perks have any duplicates, returns new array with only unique perks
            let uniquePerks = [];
            var unique = arrayOfPerks.filter((element) => {
                const isDuplicate = uniquePerks.includes(element.perk.name);

                if (!isDuplicate) {
                    uniquePerks.push(element.perk.name);

                    return true;
                }

                return false;
            });

            //only use images that have "perk" in it to avoid missing images
            unique = unique.filter((el) => {
                return el.perk.iconRef.includes("perk");
            });
            // console.log(unique);

            // var dad = unique.filter((el) => {
            //     // return el.perk.rarity == 2;
            //     return el.perk.name.includes("Perk_2_Bow_ProjectilesSplit");
            //     return el.perk.statusLevels != null;
            // });
            // console.log("DDDDDDDDDD", dad);

            //adding the name and description localizations to the perk object
            for (let i = 0; i < unique.length; i++) {
                unique[i].classifier = [];

                if (unique[i].perk.name == "Perk_2_Bow_ProjectilesSplit15") {
                    unique[i].perk.name = "Perk_2_Bow_ProjectilesSplit";
                }

                let name = arrayOfLocs.find((loc) => {
                    return loc.key.toString() == unique[i].perk.displayName;
                });
                let desc = arrayOfLocs.find((loc) => {
                    return loc.key.toString() == unique[i].perk.description;
                });
                let result = { name: name, description: desc };

                exception(unique[i], statusEffects);

                perkClassifier(unique[i]);

                if (!result) {
                    unique.splice(i, 1);
                    i -= 1;
                } else {
                    unique[i].locs = result;
                }
            }

            let test = unique.filter((perk) => {
                // return perk.perk.iconRef.includes("fire");
                return perk.locs.name["English (United States)"].includes(
                    "Whirling Shards"
                    //DASH
                );
            });
            console.log(test);
            // console.log(unique);
            let testdiv = createPerkDiv(unique, weaponName);
            // console.log(test);
            console.log(testdiv);
            console.log(el);
            el.appendChild(testdiv);
            // return test;
        }
    }, []);

    // document.getElementById("tester").appendChild(test);
    let statusImg = require(`./assets/statuseffecticons/icon_status_effect_${data.abilityPassive[2][2]}.png`);
    let passiveImg = require(`./assets/Combined/${data.abilityPassive[1][2]}.png`);
    let passiveBorder = require(`./assets/Perks/ui/icon_perk_common.png`);
    let abilityBorder = require(`./assets/Abilities/icon_ability_frame.png`);
    let abilityImg = require(`./assets/Abilities/icon_ability_${data.abilityPassive[0][2]}.png`);
    let imgSrc = require(`./assets/weaponImages/${data.currentWeapon.iconRef}.png`);
    let borderSrc = require(`./assets/Perks/ui/icon_perk_epic.png`);
    let iconSrc = require(`./assets/Perks/axe/epic/perk_epic_open_the_void.png`);
    return (
        <div
            style={{
                backgroundColor: "#0F152D",
                paddingTop: 20,
            }}
        >
            <a className="backbutton" onClick={() => navigate("/")}>
                <div
                    style={{
                        height: 50,
                        width: 120,
                        backgroundColor: "white",
                        borderRadius: 20,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: 10,
                    }}
                >
                    <FontAwesomeIcon icon={faAngleLeft} size="lg" />
                    <p
                        style={{
                            color: "black",
                            textAlign: "center",
                            marginLeft: 10,
                        }}
                    >
                        Weapons
                    </p>
                </div>
            </a>

            <div
                style={{
                    backgroundColor: "#0F152D",
                    height: "100%",
                    width: "100%",
                    paddingTop: 10,
                }}
            >
                <div
                    className="perksContainer"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: 50,
                        paddingTop: 30,
                        marginTop: 50,
                    }}
                >
                    <div
                        className="perksImage"
                        style={{
                            backgroundColor: "#e6f0fd",
                            borderRadius: 15,
                            width: 164,
                            height: 192,
                        }}
                    >
                        <img
                            src={imgSrc}
                            style={{
                                height: "100%",
                                width: "100%",
                            }}
                        />
                    </div>

                    <div
                        className="perkNameDesc"
                        style={{
                            display: "flex",
                            justifyContent: "center",

                            flexDirection: "column",
                            marginLeft: 40,
                        }}
                    >
                        <h1
                            className="perkNameText"
                            style={{
                                color: "white",
                                fontSize: 40,
                                paddingBottom: 50,
                            }}
                        >
                            {data.displayName}
                        </h1>
                        <h3
                            className="perkDescText"
                            style={{
                                color: "white",
                                maxWidth: 400,
                                lineHeight: 1.5,
                            }}
                        >
                            "{data.displayDesc}"
                        </h3>
                    </div>
                    <div
                        className="abilitypassive"
                        style={{
                            marginLeft: 150,
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 20,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <h3
                                className="abilityText"
                                style={{
                                    color: "#BDFDEC",
                                    fontSize: 25,
                                    marginBottom: 10,
                                }}
                            >
                                Ability:
                            </h3>
                            <div
                                style={{
                                    position: "relative",
                                    top: 0,
                                    left: 0,
                                }}
                            >
                                <img
                                    className="iconborder"
                                    style={{
                                        position: "relative",
                                        top: 0,
                                        left: 0,
                                        width: screenWidth / 11,
                                        height: screenWidth / 11,
                                    }}
                                    src={abilityBorder}
                                />
                                <img
                                    className="iconimg"
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: screenWidth / 11,
                                        height: screenWidth / 11,
                                    }}
                                    src={abilityImg}
                                />
                            </div>
                            <h3 className="abilityActualName">
                                {data.abilityPassive[0][0]}
                            </h3>
                        </div>
                        <div
                            className="passiveContainer"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginLeft: 50,
                                marginRight: 50,
                            }}
                        >
                            <h3
                                className="abilityText"
                                style={{
                                    color: "#BDFDEC",
                                    fontSize: 25,
                                    marginBottom: 10,
                                }}
                            >
                                Passive:
                            </h3>
                            <div
                                style={{
                                    position: "relative",
                                    top: 0,
                                    left: 0,
                                }}
                            >
                                <img
                                    className="iconborder"
                                    style={{
                                        position: "relative",
                                        top: 0,
                                        left: 0,
                                        width: screenWidth / 11,
                                        height: screenWidth / 11,
                                    }}
                                    src={passiveBorder}
                                />
                                <img
                                    className="iconimg"
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: screenWidth / 11,
                                        height: screenWidth / 11,
                                    }}
                                    src={passiveImg}
                                />
                            </div>
                            <h3 className="abilityActualName">
                                {data.abilityPassive[1][0]}
                            </h3>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <h3
                                className="abilityText"
                                style={{
                                    color: "#BDFDEC",
                                    fontSize: 25,
                                    marginBottom: 10,
                                }}
                            >
                                Effect:
                            </h3>
                            <div
                                style={{
                                    position: "relative",
                                    top: 0,
                                    left: 0,
                                }}
                            >
                                <img
                                    className="iconborder"
                                    style={{
                                        position: "relative",
                                        top: 0,
                                        left: 0,
                                        // display: "none",
                                        width: screenWidth / 11,
                                        height: screenWidth / 11,
                                    }}
                                    src={passiveBorder}
                                />
                                <img
                                    className="iconspecial "
                                    style={{
                                        position: "absolute",
                                        top: 20,
                                        left: 23,
                                        width: screenWidth / 17,
                                        height: screenWidth / 17,
                                    }}
                                    src={statusImg}
                                />
                            </div>

                            <h3 className="abilityActualName">
                                {data.abilityPassive[2][0]}
                            </h3>
                        </div>
                    </div>
                </div>
                <div
                    className="blueseparator"
                    style={{
                        width: "100%",
                        backgroundColor: "#6FC0DA",
                        height: 30,
                        marginTop: 50,

                        boxShadow: "1px 2px 9px #00C1FF",
                        // marginBottom: 50,
                    }}
                ></div>
            </div>
            <div
                id="tester"
                style={{
                    paddingLeft: 40,
                    paddingBottom: 100,
                    paddingTop: 40,
                    backgroundColor: "rgb(25, 25, 52, 1)",
                }}
            ></div>
        </div>
    );
}

export default Perks;
