import "./Home.css";

import axios from "axios";
import React, { useState, useEffect } from "react";

import Filter from "./Filter";
import { convertNumToRarity, effectClassifier, perkClassifier } from "./utils";
import { exception } from "./exceptions";
import { returndiv, testcooldown } from "./cssutils";

const { innerWidth: screenWidth, innerHeight: screenHeight } = window;

const endpointURL =
    "https://us-central1-dungeoneer-prod.cloudfunctions.net/communityContentDetails";

const Home = () => {
    const [isLoading, setLoading] = useState(true);

    var rarityOne = "rarityOne";
    var rarityTwo = "rarityTwo";
    var rarityThree = "rarityThree";
    var rarityFour = "rarityFour";
    var rarityFive = "rarityFive";

    var arrayOfLocs = [];

    useEffect(() => {
        axios.get("lfglocs.json").then((response) => {
            filterLocsForPerks(response.data);
        });

        axios.get(endpointURL).then((response) => {
            let testData = response.data.statusEffects.filter((effect) => {
                return effect.name.includes(
                    "Buff_Perk_5_Lightning_Staff_LightningOrb_Stacks1"
                );
            });
            console.log(testData);
            filterPerks(
                response.data,
                1,
                rarityOne,
                response.data.statusEffects
            );
            filterPerks(
                response.data,
                2,
                rarityTwo,
                response.data.statusEffects
            );
            filterPerks(
                response.data,
                3,
                rarityThree,
                response.data.statusEffects
            );
            filterPerks(
                response.data,
                4,
                rarityFour,
                response.data.statusEffects
            );
            filterPerks(
                response.data,
                5,
                rarityFive,
                response.data.statusEffects
            );
            setLoading(false);
        });
    }, []);

    //function to filter the localizations and create an array of matching name, description objects
    function filterLocsForPerks(data) {
        var locsWithPerks = data.filter((loc) => {
            return loc["key"].includes("Perk");
        });
        let nameObj = {};
        let descriptionObj = {};

        // var arrayOfLocs = [];
        for (
            let i = 0;
            i < locsWithPerks.length;
            locsWithPerks[i]["English (United States)"] == "Slice"
                ? (i += 1)
                : locsWithPerks[i]["English (United States)"] ==
                  "Each stack of Holy Light increases your chance for a Critical Hit."
                ? (i += 1)
                : (i += 2)
        ) {
            if (locsWithPerks[i]["English (United States)"] == "Slice") {
                // console.log(locsWithPerks[i]["English (United States)"]);
                continue;
            }
            if (
                locsWithPerks[i]["English (United States)"] ==
                "Each stack of Holy Light increases your chance for a Critical Hit."
            ) {
                // console.log(locsWithPerks[i]["English (United States)"]);
                continue;
            }

            // console.log(i);
            // console.log(locsWithPerks[i]["English (United States)"]);

            locsWithPerks[i].key = locsWithPerks[i].key.trim().split(/\s+/);
            locsWithPerks[i + 1].key = locsWithPerks[i + 1].key
                .trim()
                .split(/\s+/);
            // console.log(locsWithPerks[i]);

            if (locsWithPerks[i].key[1] == "DESCRIPTION") {
                descriptionObj = locsWithPerks[i];

                nameObj = locsWithPerks[i + 1];
            } else if (locsWithPerks[i].key[1] == "NAME") {
                nameObj = locsWithPerks[i];

                descriptionObj = locsWithPerks[i + 1];
            } else {
                console.log("perk with no name or desc");
            }

            if (nameObj.key[0] == "Perk_3_Bow_Fire_IncreaseDuration_Burn") {
                descriptionObj["English (United States)"] =
                    "Burn duration is increased.";
            }
            if (nameObj.key[0] == "Perk_4_Bow_Fire_ExplosiveDamageToBurning") {
                descriptionObj["English (United States)"] =
                    "Explosive Shot deals extra damage to Burning enemies.";
            }
            if (
                nameObj.key[0] ==
                "Perk_4_Bow_Fire_CritsGivePowerBoostUntilRefresh"
            ) {
                descriptionObj["English (United States)"] =
                    "Critical Hits give stacks of increased Power. At max stacks, lose all stacks and refresh your ability.";
            }
            if (
                nameObj.key[0] == "Perk_4_Staff_Lightning_OnStruck_DamageStun"
            ) {
                descriptionObj["English (United States)"] =
                    "When Struck you deal damage in return and have a chance to Stun the attacking target.";
            }
            if (nameObj.key[0] == "Perk_4_Staff_Lightning_ChainLightning") {
                descriptionObj["English (United States)"] =
                    "Attacks bounce to additional targets.";
            }
            // console.log(nameObj);

            arrayOfLocs.push({
                name: nameObj,
                description: descriptionObj,
            });
            // nameObj = {};
            // descriptionObj = {};
        }
        // arrayOfLocs.shift();
    }

    // //function to take all data and filter for specific perks and render
    function filterPerks(data, rarity, divChoice, statusEffects) {
        // console.log(rarity);
        let weapons = data.weapons;
        var weaponsWithPerks = weapons.filter((weapon) => {
            return weapon.perks.length > 0;
        });
        weaponsWithPerks = weaponsWithPerks.filter((weapon) => {
            return (
                !weapon.$path.includes("Old") &&
                !weapon.$path.includes("New") &&
                !weapon.$path.includes("RuneBalance")
            );
        });

        var perksWithRarity = [];
        var arrayOfPerks = [];

        //filtering for a specific rarity of perk
        for (var i = 0; i < weaponsWithPerks.length; i++) {
            perksWithRarity = weaponsWithPerks[i].perks.filter((perk) => {
                return perk.perk.rarity == rarity;
            });

            for (var perkI of perksWithRarity) {
                arrayOfPerks.push(perkI);
            }
        }
        // console.log(arrayOfPerks);

        //Checks if perks have any duplicates, returns new array with only unique perks
        let uniquePerks = [];
        var unique = arrayOfPerks.filter((element) => {
            const isDuplicate = uniquePerks.includes(element.perk.name);

            if (!isDuplicate) {
                uniquePerks.push(element.perk.name);

                return true;
            }

            return false;
        });

        //only use images that have "perk" in it to avoid missing images
        unique = unique.filter((el) => {
            return el.perk.iconRef.includes("perk");
        });

        // var dad = unique.filter((el) => {
        //     // return el.perk.rarity == 2;
        //     return el.perk.name.includes("Perk_2_Bow_ProjectilesSplit");
        //     return el.perk.statusLevels != null;
        // });
        // console.log("DDDDDDDDDD", dad);

        //adding the name and description localizations to the perk object
        for (let i = 0; i < unique.length; i++) {
            unique[i].classifier = [];
            // console.log("hdwdw", arrayOfLocs);
            // console.log(unique[i].perk.name);
            if (unique[i].perk.name == "Perk_2_Bow_ProjectilesSplit15") {
                unique[i].perk.name = "Perk_2_Bow_ProjectilesSplit";
            }
            let result = arrayOfLocs.find((loc) => {
                // return loc.name.key[0] == "Perk_2_Bow_ProjectilesSplit";
                // console.log(loc);
                return loc.name.key[0] == unique[i].perk.name;
            });
            // console.log(result);

            exception(unique[i], statusEffects);

            perkClassifier(unique[i]);

            if (!result) {
                unique.splice(i, 1);
                i -= 1;
            } else {
                unique[i].locs = result;
            }
        }

        let test = unique.filter((perk) => {
            // return perk.perk.iconRef.includes("fire");
            return perk.locs.name["English (United States)"].includes(
                "Lightning Orb"
                //DASH
            );
        });
        console.log(test);
        createPerkDiv(unique, divChoice);
    }

    // //Creates div for each perk dynamically
    function createPerkDiv(data, divChoice) {
        for (var i = 0; i < data.length; i++) {
            var div = document.createElement("div");

            let selectedDiv;
            let category;

            // console.log(divChoice);
            //set specific div to place certain category of weapons
            if (data[i].perk.name.includes("Axe")) {
                selectedDiv = document.getElementById(divChoice + "Axes");
                category = "axe";
            } else if (data[i].perk.name.includes("Bow")) {
                selectedDiv = document.getElementById(divChoice + "Bows");
                category = "bow";
            } else if (data[i].perk.name.includes("Sword")) {
                selectedDiv = document.getElementById(divChoice + "Swords");
                category = "sword";
            } else if (data[i].perk.name.includes("Staff")) {
                // console.log(data[i].perk.name);
                selectedDiv = document.getElementById(divChoice + "Staves");
                category = "staff";
            } else {
                selectedDiv = document.getElementById(divChoice + "General");
                category = "general";
            }
            // console.log(selectedDiv);
            let increaseDurationIcon = require(`./assets/Combined/perk_uncommon_cooldown_reduction.png`);
            let increaseDMGIcon = require(`./assets/Combined/perk_common_extra_damage.png`);
            let sphereIcon = require(`./assets/staticons/icon_perk_glyph_attackrange.png`);
            let speedIcon = require(`./assets/staticons/icon_perk_glyph_runspeed.png`);
            let critIcon = require(`./assets/Combined/perk_common_chance_to_crit.png`);
            let attackspeedIcon = require(`./assets/Combined/perk_uncommon_knocked_loaded.png`);
            let armorIcon = require(`./assets/Combined/perk_common_chance_to_block.png`);
            let healIcon = require(`./assets/Combined/perk_common_extra_health.png`);
            let bleedIcon = require(`./assets/statuseffecticons/icon_status_effect_bleed.png`);
            let fireIcon = require(`./assets/statuseffecticons/icon_status_effect_fire.png`);
            let staticIcon = require(`./assets/statuseffecticons/icon_status_effect_shock.png`);
            let markIcon = require(`./assets/statuseffecticons/icon_status_effect_marked.png`);
            let curseIcon = require(`./assets/statuseffecticons/icon_status_effect_shadow.png`);
            let chillIcon = require(`./assets/statuseffecticons/icon_status_effect_ice.png`);
            let inspireIcon = require(`./assets/statuseffecticons/icon_status_effect_holy_light.png`);
            let reflectIcon = require(`./assets/Combined/perk_uncommon_dark_defense.png`);
            let critDmgIcon = require(`./assets/Combined/perk_common_extra_crit_damage.png`);
            let chanceIcon = require(`./assets/Combined/perk_epic_lightning_reflexes.png`);
            let resolveIcon = require(`./assets/Combined/perk_rare_sacred_concoction.png`);
            let imgSrc = require(`./assets/Combined/${data[i].perk.iconRef}.png`);
            let borderSrc = require(`./assets/Perks/ui/icon_perk_${convertNumToRarity(
                data[i].perk.rarity
            )}.png`);

            let dmgText = "";
            if (
                data[i].perk.name == "Perk_2_Melee_Projectile" ||
                data[i].perk.name == "Perk_2_Sword_Knives" ||
                data[i].perk.name == "Perk_2_Bow_ProjectilesSplit" ||
                data[i].perk.name == "Perk_2_Bow_OpeningShot" ||
                data[i].perk.name == "Perk_2_Bow_RollLeavesTrap"
            ) {
                dmgText = "Increase Damage (Of Base) On Effect";
            } else {
                dmgText = "Increases damage of base attacks";
            }

            let display3ranks = false;
            if (
                data[i].perk.maxAllowed == 3 ||
                (data[i].effectClass && data[i].effectClass != "undefined") ||
                data[i].classifier.includes("increase_duration") ||
                data[i].perk.type == "OrbitalPerkSO" ||
                data[i].classifier?.[0]?.split(/[-_]+/).length > 2
            ) {
                if (
                    data[i].perk.maxAllowed == 3 ||
                    data[i].perk.type == "OrbitalPerkSO" ||
                    data[i].classifier.includes("increase_duration") ||
                    data[i].classifier?.[0]?.split(/[-_]+/).length > 2 ||
                    data[i].effectClass.split(/[-_]+/)[2]
                ) {
                    display3ranks = true;
                }
            }

            // console.log(data[i].classifier?.[0]?.includes("damage_multi"));
            div.innerHTML =
                (screenWidth < 500
                    ? "<div style=' display: flex; flex-direction: column; align-items: flex-start; margin-bottom: 20vw'>"
                    : "") +
                "<div class='mobilemarginzero' style='margin-bottom: 50px; display: flex; flex-direction: row; align-items: center; justify-content: flex-start'>" +
                "<div style='position: relative; top: 0px; left:0px;  '>" +
                `<img class='iconborder' style='position: relative; top: 0px; left: 0px; width: ${
                    screenWidth / 11
                }px; height: ${screenWidth / 11}px ' src=` +
                borderSrc +
                ">" +
                `<img class='iconimg' style='position: absolute; top: 0px; left: 0px;width: ${
                    screenWidth / 11
                }px; height: ${screenWidth / 11}px' src=` +
                imgSrc +
                ">" +
                "</div>" +
                `<div class='namedesc' style='display: flex; flex-direction: column; padding-left: 20px; margin-top: 10px; width:${
                    screenWidth / 3.56
                }px; padding-right: 10px '>` +
                "<h3 style='margin: 0; font-size: 23px'>" +
                data[i].locs.name["English (United States)"] +
                "<p style='font-size: 15px'>(" +
                data[i].perk.name +
                ")</p>" +
                "</h3>" +
                "<p style='font-size: 17px; line-height: 25px'>" +
                //Adding description to the perk based on name, according to ./utils.js
                data[i].locs.description["English (United States)"] +
                " " +
                "</p>" +
                "</div>" +
                (screenWidth < 500 ? "</div>" : "") +
                "<div style='display: flex; flex-direction: row; justify-content: center; padding-right: 100px'>" +
                //First Row

                "<div style='display: flex; flex-direction: column; justify-content: center; width: 80px; margin-left: 20px'>" +
                (data[i].perk.rarity == 1
                    ? "<p style='font-size: 16px; margin-left: 0px;text-align: center;  color: #b6403a'>At Every Rank:</p>"
                    : "<p style='font-size: 16px; margin-left: 0px;  color: #b6403a'>At Rank 1:</p>" +
                      //   ((data[i].effectClass &&
                      //       data[i].effectClass != "undefined") ||
                      //   data[i].perk.type == "OrbitalPerkSO"
                      //       ? data[i].perk.type == "OrbitalPerkSO" ||
                      //         data[i].effectClass.split(/[-_]+/)[2]
                      (display3ranks == true
                          ? "<p style='font-size: 16px; margin-left: 0px; margin-top: 50px; margin-bottom: 50px; color: #b6403a'>At Rank 2: </p>" +
                            "<p style='font-size: 16px; margin-left: 0px; color: #b6403a'>At Rank 3: </p>"
                          : "")) +
                "</div>" +
                (isNaN(data[i].perk.percent * 100)
                    ? ""
                    : data[i].perk.maxAllowed == 0 ||
                      data[i].perk.maxAllowed == 1
                    ? "<div class='statbox'><img style='width: 64px; height: 64px' src=" +
                      imgSrc +
                      ">" +
                      "<p class='statboxtext'>" +
                      data[i].perk.percent * 100 +
                      "%</p></div>"
                    : "") +
                (data[i].classifier?.[0]?.includes("elementsphere")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' height: 64px'>" +
                      "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                      imgSrc +
                      ">" +
                      "<p class='statboxtext'> " +
                      "1</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >Increase Projectiles</h1></div></div>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' height: 64px; margin-top: 20px; margin-bottom: 20px'>" +
                      "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                      imgSrc +
                      ">" +
                      "<p class='statboxtext'> " +
                      "2</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >Increase Projectiles</h1></div></div>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' height: 64px; '>" +
                      "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                      imgSrc +
                      ">" +
                      "<p class='statboxtext'> " +
                      "3</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >Increase Projectiles</h1></div></div>" +
                      "</div>"
                    : "") +
                //Second Row
                (data[i].classifier?.[0]?.includes("elementsphere") &&
                data[i].classifier?.[0] != "elementspherereflect"
                    ? "<div class='statbox'>" +
                      "<img style='width: 64px; height: 64px' src=" +
                      increaseDMGIcon +
                      ">" +
                      "<p class='statboxtext'> " +
                      data[i].classifier[0].split(/[_]+/).slice(-1)[0] * 100 +
                      "%</p></div>"
                    : "") +
                //Second Row
                //Second Row
                (data[i].classifier?.[0]?.includes("elementspherereflect")
                    ? "<div class='statbox'>" +
                      "<img style='width: 64px; height: 64px' src=" +
                      reflectIcon +
                      ">" +
                      "<p class='statboxtext'> " +
                      "Reflect" +
                      "<br>Projectiles" +
                      "</p></div>"
                    : "") +
                //Second Row
                (data[i].classifier?.[1]?.includes("radius") ||
                data[i].extra?.[0]?.includes("radius")
                    ? "<div class='statbox'>" +
                      "<img style='width: 64px; height: 64px' src=" +
                      sphereIcon +
                      ">" +
                      "<p class='statboxtext'>" +
                      (data[i].classifier?.[1]?.includes("radius")
                          ? parseFloat(
                                data[i].classifier[1]
                                    .split(/[-_]+/)
                                    .slice(-1)[0]
                            )
                          : data[i].extra[0].split(/[-_]+/).slice(-1)[0]) +
                      "</p></div>"
                    : "") +
                //Second Row
                //Second Row
                (data[i].classifier?.[1]?.includes("maxstacks")
                    ? "<div class='statbox'>" +
                      "<img style='width: 64px; height: 64px' src=" +
                      sphereIcon +
                      ">" +
                      "<p class='statboxtext'>" +
                      parseFloat(
                          data[i].classifier[1].split(/[-_]+/).slice(-1)[0]
                      ) +
                      "</p></div>"
                    : "") +
                //Second Row
                (data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("special")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                      "<img style='width: 64px; height: 64px' src=" +
                      imgSrc +
                      ">" +
                      "<p class='statboxtext'> " +
                      parseFloat(data[i].effectClass.split(/[-_]+/)[1]) * 100 +
                      "%</p></div></a> <div class='popover__content'>" +
                      `<h1 class='popover__message' >${dmgText}</h1></div></div>` +
                      (data[i].effectClass.split(/[_]+/)[2]
                          ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                            "<img style='width: 64px; height: 64px' src=" +
                            imgSrc +
                            ">" +
                            "<p class='statboxtext'> " +
                            Number(
                                (
                                    parseFloat(
                                        data[i].effectClass.split(/[-_]+/)[2]
                                    ) * 100
                                ).toFixed(2)
                            ) +
                            "%</p></div></a> <div class='popover__content'>" +
                            `<h1 class='popover__message' >${dmgText}</h1></div></div>`
                          : "") +
                      (data[i].effectClass.split(/[_]+/)[3]
                          ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                            "<img style='width: 64px; height: 64px' src=" +
                            imgSrc +
                            ">" +
                            "<p class='statboxtext'>" +
                            parseFloat(data[i].effectClass.split(/[-_]+/)[3]) *
                                100 +
                            "%</p></div></a> <div class='popover__content'>" +
                            `<h1 class='popover__message' >${dmgText}</h1></div></div>`
                          : "") +
                      "</div>"
                    : "") +
                //Second Row
                //Second Row
                (data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("cooldownAccelerationBuff")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                      "<img style='width: 64px; height: 64px' src=" +
                      attackspeedIcon +
                      ">" +
                      "<p class='statboxtext'> " +
                      parseFloat(data[i].effectClass.split(/[-_]+/)[1]) * 100 +
                      "%</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >Increases Attack Speed</h1></div></div>" +
                      (data[i].effectClass.split(/[_]+/)[2]
                          ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                            "<img style='width: 64px; height: 64px' src=" +
                            attackspeedIcon +
                            ">" +
                            "<p class='statboxtext'> " +
                            Number(
                                (
                                    parseFloat(
                                        data[i].effectClass.split(/[-_]+/)[2]
                                    ) * 100
                                ).toFixed(2)
                            ) +
                            "%</p></div></a> <div class='popover__content'>" +
                            "<h1 class='popover__message' >Increases Attack Speed</h1></div></div>"
                          : "") +
                      (data[i].effectClass.split(/[_]+/)[3]
                          ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                            "<img style='width: 64px; height: 64px' src=" +
                            attackspeedIcon +
                            ">" +
                            "<p class='statboxtext'>" +
                            parseFloat(data[i].effectClass.split(/[-_]+/)[3]) *
                                100 +
                            "%</p></div></a> <div class='popover__content'>" +
                            "<h1 class='popover__message' >Increases Attack Speed</h1></div></div>"
                          : "") +
                      "</div>"
                    : "") +
                //Second Row
                //Second Row
                ((data[i].effectClass &&
                    data[i].effectClass != "undefined" &&
                    data[i].effectClass.includes("chance")) ||
                data[i].classifier?.[0]?.includes("chance") ||
                data[i].anotherRankClassifier?.[0]?.includes("chance") ||
                data[i].testRankClassifier?.[0]?.includes("chance") ||
                data[i].extra?.includes("chance")
                    ? testcooldown(
                          data[i].effectClass,
                          "chance",
                          "Chance to apply",
                          chanceIcon,
                          data[i].anotherRankClassifier,
                          data[i].testRankClassifier,
                          "%",
                          100,
                          data[i].extra
                      )
                    : // ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      //   "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                      //   "<img style='width: 64px; height: 64px' src=" +
                      //   chanceIcon +
                      //   ">" +
                      //   "<p class='statboxtext'> " +
                      //   parseFloat(data[i].effectClass.split(/[-_]+/)[1]) * 100 +
                      //   "%</p></div></a> <div class='popover__content'>" +
                      //   "<h1 class='popover__message' >Chance to apply Effect</h1></div></div>" +
                      //   (data[i].effectClass.split(/[_]+/)[2]
                      //       ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                      //         "<img style='width: 64px; height: 64px' src=" +
                      //         chanceIcon +
                      //         ">" +
                      //         "<p class='statboxtext'> " +
                      //         Number(
                      //             (
                      //                 parseFloat(
                      //                     data[i].effectClass.split(/[-_]+/)[2]
                      //                 ) * 100
                      //             ).toFixed(2)
                      //         ) +
                      //         "%</p></div></a> <div class='popover__content'>" +
                      //         "<h1 class='popover__message' >Chance to apply Effect</h1></div></div>"
                      //       : "") +
                      //   (data[i].effectClass.split(/[_]+/)[3]
                      //       ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                      //         "<img style='width: 64px; height: 64px' src=" +
                      //         chanceIcon +
                      //         ">" +
                      //         "<p class='statboxtext'>" +
                      //         parseFloat(data[i].effectClass.split(/[-_]+/)[3]) *
                      //             100 +
                      //         "%</p></div></a> <div class='popover__content'>" +
                      //         "<h1 class='popover__message' >Chance to apply Effect</h1></div></div>"
                      //       : "") +
                      //   "</div>"
                      "") +
                //Second Row
                //Second Row
                ((data[i].effectClass &&
                    data[i].effectClass != "undefined" &&
                    data[i].effectClass.includes("hpMultiplier")) ||
                data[i].classifier?.[0]?.includes("hpMultiplier") ||
                data[i].anotherRankClassifier?.[0]?.includes("hpMultiplier") ||
                data[i].testRankClassifier?.[0]?.includes("hpMultiplier") ||
                data[i].extra?.includes("hpMultiplier")
                    ? testcooldown(
                          data[i].effectClass,
                          "hpMultiplier",
                          "Raven Health",
                          healIcon,
                          data[i].anotherRankClassifier,
                          data[i].testRankClassifier,
                          "%",
                          100,
                          data[i].extra
                      )
                    : "") +
                //Second Row
                //Second Row
                ((data[i].effectClass &&
                    data[i].effectClass != "undefined" &&
                    data[i].effectClass.includes("speedBuff")) ||
                data[i].classifier?.[0]?.includes("speedBuff") ||
                data[i].anotherRankClassifier?.[0]?.includes("speedBuff") ||
                data[i].testRankClassifier?.[0]?.includes("speedBuff") ||
                data[i].extra?.includes("speedBuff")
                    ? testcooldown(
                          data[i].effectClass,
                          "speedBuff",
                          data[i].anotherRankClassifier?.[1] < 0
                              ? "Slow Enemies"
                              : "Increase Movement Speed",
                          speedIcon,
                          data[i].anotherRankClassifier,
                          false,
                          "%",
                          100,
                          data[i].extra,
                          44,
                          "",
                          true
                      )
                    : "") +
                //Second Row
                //Second Row
                ((data[i].effectClass &&
                    data[i].effectClass != "undefined" &&
                    data[i].effectClass.includes("applyholylight")) ||
                data[i].classifier?.[0]?.includes("applyholylight") ||
                data[i].anotherRankClassifier?.[0]?.includes(
                    "applyholylight"
                ) ||
                data[i].testRankClassifier?.[0]?.includes("applyholylight") ||
                data[i].extra?.includes("applyholylight")
                    ? testcooldown(
                          data[i].effectClass,
                          "applyholylight",
                          "Apply Holy Light Stacks",
                          imgSrc,
                          data[i].anotherRankClassifier,
                          false,
                          "",
                          1,
                          false,
                          64,
                          "",
                          false
                      )
                    : "") +
                //Second Row
                //Second Row
                (data[i].classifier?.[1] &&
                data[i].classifier?.[1] != "undefined" &&
                data[i].classifier?.[1].includes("bighit")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                      "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                      imgSrc +
                      ">" +
                      "<p class='statboxtext'> " +
                      "(On Hit)</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >Apply Big Hit On Hit</h1></div></div>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px;margin-top: 20px; margin-bottom: 20px'>" +
                      "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                      imgSrc +
                      ">" +
                      "<p class='statboxtext'> " +
                      "(On Hit)</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >Apply Big Hit On Hit</h1></div></div>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                      "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                      imgSrc +
                      ">" +
                      "<p class='statboxtext'> " +
                      "(On Hit)</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >Apply Big Hit On Hit</h1></div></div>" +
                      "</div>"
                    : "") +
                //Second Row
                //Second Row
                (data[i].apply &&
                data[i].apply != "undefined" &&
                data[i].apply.includes("ondefeat")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                      "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                      imgSrc +
                      ">" +
                      "<p class='statboxtext'> " +
                      `(On Defeat)</p></div></a> <div class='popover__content'>` +
                      "<h1 class='popover__message' >Apply On Defeat</h1></div></div>" +
                      (data[i].perk.maxAllowed > 1
                          ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px;width: 150px; height: 64px'>" +
                            "<img style='width: 44px; height: 44px' src=" +
                            imgSrc +
                            ">" +
                            "<p class='statboxtext'> " +
                            `(On Defeat)</p></div></a> <div class='popover__content'>` +
                            "<h1 class='popover__message' >Apply On Defeat</h1></div></div>" +
                            "<div class='popover__wrapper' ><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                            "<img style='width: 44px; height: 44px' src=" +
                            imgSrc +
                            ">" +
                            "<p class='statboxtext'>" +
                            `(On Defeat)</p></div></a> <div class='popover__content'>` +
                            "<h1 class='popover__message' >Apply On Defeat</h1></div></div>"
                          : "") +
                      "</div>"
                    : "") +
                //Second Row
                //Second Row
                (data[i].perk.name.includes("Perk_2_Bow_Puncture")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                      "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                      imgSrc +
                      ">" +
                      "<p class='statboxtext'> " +
                      `+Piercing</p></div></a> <div class='popover__content'>` +
                      "<h1 class='popover__message' >Add Piercing Effect</h1></div></div>" +
                      "</div>"
                    : "") +
                //Second Row
                //Second Row
                (data[i].classifier &&
                data[i].classifier != "undefined" &&
                data[i].classifier.includes("applybleed")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                      "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                      bleedIcon +
                      ">" +
                      "<p class='statboxtext'> " +
                      `(On ${
                          data[i].perk.name == "Perk_2_Axe_CritAppliesBleed"
                              ? "Crit"
                              : "Hit"
                      })</p></div></a> <div class='popover__content'>` +
                      `<h1 class='popover__message' >Apply Bleed On ${
                          data[i].perk.name == "Perk_2_Axe_CritAppliesBleed"
                              ? "Crit"
                              : "Hit"
                      }</h1></div></div>` +
                      (data[i].effectClass
                          ? (data[i].effectClass.split(/[_]+/)[2]
                                ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px;width: 150px; height: 64px'>" +
                                  "<img style='width: 44px; height: 44px' src=" +
                                  bleedIcon +
                                  ">" +
                                  "<p class='statboxtext'> " +
                                  `(On ${
                                      data[i].perk.name ==
                                      "Perk_2_Axe_CritAppliesBleed"
                                          ? "Crit"
                                          : "Hit"
                                  })</p></div></a> <div class='popover__content'>` +
                                  "<h1 class='popover__message' >Apply Bleed on Hit</h1></div></div>"
                                : "") +
                            (data[i].effectClass.split(/[_]+/)[3]
                                ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                                  "<img style='width: 44px; height: 44px' src=" +
                                  bleedIcon +
                                  ">" +
                                  "<p class='statboxtext'>" +
                                  `(On ${
                                      data[i].perk.name ==
                                      "Perk_2_Axe_CritAppliesBleed"
                                          ? "Crit"
                                          : "Hit"
                                  })</p></div></a> <div class='popover__content'>` +
                                  "<h1 class='popover__message' >Apply Bleed On Hit</h1></div></div>"
                                : "")
                          : "") +
                      "</div>"
                    : "") +
                //Second Row
                (data[i].classifier &&
                data[i].classifier != "undefined" &&
                data[i].classifier.includes("applyresolve")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                      "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                      resolveIcon +
                      ">" +
                      "<p class='statboxtext'> " +
                      "(On Use)</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >Applies Resolve</h1></div></div>" +
                      "</div>"
                    : "") +
                //Second Row
                //Second Row
                (data[i].classifier &&
                data[i].classifier != "undefined" &&
                data[i].classifier.includes("applyinspireoncrit")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                      "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                      inspireIcon +
                      ">" +
                      "<p class='statboxtext'> " +
                      "(On Crit)</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >Applies Inspire</h1></div></div>" +
                      "</div>"
                    : "") +
                //Second Row
                //Second Row
                (data[i].classifier &&
                data[i].classifier != "undefined" &&
                data[i].classifier.includes("applyburnoncrit")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' style='width: 150px; height: 64px'>" +
                      "<img style='width: 44px; height: 44px; margin-right: 10px' src=" +
                      fireIcon +
                      ">" +
                      "<p class='statboxtext'> " +
                      "(On Crit)</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >Applies Burn</h1></div></div>" +
                      "</div>"
                    : "") +
                //Second Row
                //Second Row
                returndiv(
                    data[i].classifier,
                    "applyburn",
                    "Applies Burn",
                    fireIcon,
                    3
                ) +
                returndiv(
                    data[i].classifier,
                    "applystatic",
                    "Applies Static",
                    staticIcon,
                    3
                ) +
                returndiv(
                    data[i].classifier,
                    "applymark",
                    "Applies Mark",
                    markIcon,
                    3
                ) +
                returndiv(
                    data[i].classifier,
                    "applycurse",
                    "Applies Curse",
                    curseIcon,
                    3
                ) +
                returndiv(
                    data[i].classifier,
                    "applychill",
                    "Applies Chill",
                    chillIcon,
                    3
                ) +
                returndiv(
                    data[i].classifier,
                    "applyinspire",
                    "Applies Inspire",
                    inspireIcon,
                    3
                ) +
                //Second Row
                (data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].effectClass.includes("reflectBuff")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                      "<img style='width: 64px; height: 64px' src=" +
                      reflectIcon +
                      ">" +
                      "<p class='statboxtext'> " +
                      parseFloat(data[i].effectClass.split(/[-_]+/)[1]) * 100 +
                      "%</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >Damage buff on reflection</h1></div></div>" +
                      (data[i].effectClass.split(/[_]+/)[2]
                          ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                            "<img style='width: 64px; height: 64px' src=" +
                            reflectIcon +
                            ">" +
                            "<p class='statboxtext'> " +
                            Number(
                                (
                                    parseFloat(
                                        data[i].effectClass.split(/[-_]+/)[2]
                                    ) * 100
                                ).toFixed(2)
                            ) +
                            "%</p></div></a> <div class='popover__content'>" +
                            "<h1 class='popover__message' >Damage buff on reflection</h1></div></div>"
                          : "") +
                      (data[i].effectClass.split(/[_]+/)[3]
                          ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                            "<img style='width: 64px; height: 64px' src=" +
                            reflectIcon +
                            ">" +
                            "<p class='statboxtext'>" +
                            parseFloat(data[i].effectClass.split(/[-_]+/)[3]) *
                                100 +
                            "%</p></div></a> <div class='popover__content'>" +
                            "<h1 class='popover__message' >Damage buff on reflection</h1></div></div>"
                          : "") +
                      "</div>"
                    : "") +
                //First Row
                (data[i].classifier?.[0]?.includes("damagemulti")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                      "<img style='width: 64px; height: 64px' src=" +
                      (parseFloat(data[i].classifier[0].split(/[_]+/)[1]) < 0
                          ? healIcon
                          : increaseDMGIcon) +
                      ">" +
                      "<p class='statboxtext'> " +
                      Number(
                          (
                              parseFloat(
                                  data[i].classifier[0].split(/[-_]+/)[1]
                              ) * 100
                          ).toFixed(2)
                      ) +
                      "%</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >" +
                      (parseFloat(data[i].classifier[0].split(/[_]+/)[1]) < 0
                          ? "Healing"
                          : `${dmgText}`) +
                      "</h1></div></div>" +
                      (data[i].classifier[0].split(/[_]+/)[2]
                          ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                            "<img style='width: 64px; height: 64px' src=" +
                            (parseFloat(
                                data[i].classifier[0].split(/[_]+/)[2]
                            ) < 0
                                ? healIcon
                                : increaseDMGIcon) +
                            ">" +
                            "<p class='statboxtext'> " +
                            Number(
                                (
                                    parseFloat(
                                        data[i].classifier[0].split(/[-_]+/)[2]
                                    ) * 100
                                ).toFixed(2)
                            ) +
                            "%</p></div></a> <div class='popover__content'>" +
                            "<h1 class='popover__message' >" +
                            (parseFloat(
                                data[i].classifier[0].split(/[_]+/)[2]
                            ) < 0
                                ? "Healing"
                                : `${dmgText}`) +
                            "</h1></div></div>"
                          : "") +
                      (data[i].classifier[0].split(/[_]+/)[3]
                          ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                            "<img style='width: 64px; height: 64px' src=" +
                            (parseFloat(
                                data[i].classifier[0].split(/[_]+/)[3]
                            ) < 0
                                ? healIcon
                                : increaseDMGIcon) +
                            ">" +
                            "<p class='statboxtext'>" +
                            Number(
                                (
                                    parseFloat(
                                        data[i].classifier[0].split(/[-_]+/)[3]
                                    ) * 100
                                ).toFixed(2)
                            ) +
                            "%</p></div></a> <div class='popover__content'>" +
                            "<h1 class='popover__message' >" +
                            (parseFloat(
                                data[i].classifier[0].split(/[_]+/)[3]
                            ) < 0
                                ? "Healing"
                                : `${dmgText}`) +
                            "</h1></div></div>"
                          : "") +
                      "</div>"
                    : "") +
                //Second Row
                //First Row
                (data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].classifier.includes("damagetest")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                      "<img style='width: 64px; height: 64px' src=" +
                      (parseFloat(data[i].effectClass.split(/[_]+/)[1]) < 0
                          ? healIcon
                          : increaseDMGIcon) +
                      ">" +
                      "<p class='statboxtext'> " +
                      parseFloat(data[i].effectClass.split(/[-_]+/)[1]) * 100 +
                      "%</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >" +
                      (parseFloat(data[i].effectClass.split(/[_]+/)[1]) < 0
                          ? "Healing"
                          : data[i].perk.name == "Perk_2_Bow_Pet"
                          ? "Pet Damage"
                          : `${dmgText}`) +
                      "</h1></div></div>" +
                      (data[i].effectClass.split(/[_]+/)[2]
                          ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                            "<img style='width: 64px; height: 64px' src=" +
                            (parseFloat(data[i].effectClass.split(/[_]+/)[2]) <
                            0
                                ? healIcon
                                : increaseDMGIcon) +
                            ">" +
                            "<p class='statboxtext'> " +
                            Number(
                                (
                                    parseFloat(
                                        data[i].effectClass.split(/[-_]+/)[2]
                                    ) * 100
                                ).toFixed(2)
                            ) +
                            "%</p></div></a> <div class='popover__content'>" +
                            "<h1 class='popover__message' >" +
                            (parseFloat(data[i].effectClass.split(/[_]+/)[2]) <
                            0
                                ? "Healing"
                                : `${dmgText}`) +
                            "</h1></div></div>"
                          : "") +
                      (data[i].effectClass.split(/[_]+/)[3]
                          ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                            "<img style='width: 64px; height: 64px' src=" +
                            (parseFloat(data[i].effectClass.split(/[_]+/)[3]) <
                            0
                                ? healIcon
                                : increaseDMGIcon) +
                            ">" +
                            "<p class='statboxtext'>" +
                            parseFloat(data[i].effectClass.split(/[-_]+/)[3]) *
                                100 +
                            "%</p></div></a> <div class='popover__content'>" +
                            "<h1 class='popover__message' >" +
                            (parseFloat(data[i].effectClass.split(/[_]+/)[1]) <
                            0
                                ? "Healing"
                                : `${dmgText}`) +
                            "</h1></div></div>"
                          : "") +
                      "</div>"
                    : "") +
                //Second Row
                //First Row
                (data[i].testRankClassifier &&
                data[i].testRankClassifier != "undefined" &&
                data[i].testRankClassifier[0] == "damagetestheal"
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                      "<img style='width: 64px; height: 64px' src=" +
                      (parseFloat(data[i].testRankClassifier[1]) < 0
                          ? healIcon
                          : increaseDMGIcon) +
                      ">" +
                      "<p class='statboxtext'> " +
                      Math.abs(parseFloat(data[i].testRankClassifier[1])) *
                          100 +
                      "%</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >" +
                      (parseFloat(data[i].testRankClassifier[1]) < 0
                          ? "Healing"
                          : data[i].perk.name == "Perk_2_Bow_Pet"
                          ? "Pet Damage"
                          : `${dmgText}`) +
                      "</h1></div></div>" +
                      "</div>"
                    : "") +
                //Second Row
                //First Row
                (data[i].extra &&
                data[i].extra != "undefined" &&
                data[i].extra[0].includes("damagemultiwhirl")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                      "<img style='width: 64px; height: 64px' src=" +
                      (parseFloat(data[i].extra[1]) < 0
                          ? healIcon
                          : increaseDMGIcon) +
                      ">" +
                      "<p class='statboxtext'> " +
                      Math.abs(parseFloat(data[i].extra[1])) * 100 +
                      "%</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >" +
                      (parseFloat(data[i].extra[1]) < 0
                          ? "Healing"
                          : data[i].perk.name == "Perk_2_Bow_Pet"
                          ? "Pet Damage"
                          : `${dmgText}`) +
                      "</h1></div></div>" +
                      "</div>"
                    : "") +
                //Second Row
                ((data[i].effectClass &&
                    data[i].effectClass != "undefined" &&
                    data[i].effectClass.includes("crithance")) ||
                data[i].classifier?.[0]?.includes("crithance") ||
                data[i].anotherRankClassifier?.[0]?.includes("crithance") ||
                data[i].testRankClassifier?.[0]?.includes("crithance") ||
                data[i].extra?.includes("crithance")
                    ? testcooldown(
                          data[i].effectClass,
                          "crithance",
                          "Increase Crit Chance",
                          critIcon,
                          false,
                          data[i].testRankClassifier,
                          "%",
                          100,
                          data[i].extra
                      )
                    : "") +
                //Second Row
                //Second Row
                ((data[i].effectClass &&
                    data[i].effectClass != "undefined" &&
                    data[i].effectClass.includes("maxHealthBuff")) ||
                data[i].classifier?.[0]?.includes("maxHealthBuff") ||
                data[i].anotherRankClassifier?.[0]?.includes("maxHealthBuff") ||
                data[i].testRankClassifier?.[0]?.includes("maxHealthBuff") ||
                data[i].extra?.includes("maxHealthBuff")
                    ? testcooldown(
                          data[i].effectClass,
                          "maxHealthBuff",
                          "Increase Max Hp",
                          healIcon,
                          data[i].anotherRankClassifier,
                          data[i].testRankClassifier,
                          "%",
                          100,
                          data[i].extra
                      )
                    : "") +
                //Second Row
                //Second Row
                ((data[i].effectClass &&
                    data[i].effectClass != "undefined" &&
                    data[i].effectClass.includes("critdmgmulti")) ||
                data[i].classifier?.[0]?.includes("critdmgmulti") ||
                data[i].anotherRankClassifier?.[0]?.includes("critdmgmulti") ||
                data[i].testRankClassifier?.[0]?.includes("critdmgmulti") ||
                data[i].extra?.includes("critdmgmulti")
                    ? testcooldown(
                          data[i].effectClass,
                          "critdmgmulti",
                          "Crit Damage Increase",
                          critDmgIcon,
                          data[i].anotherRankClassifier,
                          data[i].testRankClassifier,
                          "%",
                          100,
                          data[i].extra
                      )
                    : "") +
                //Second Row
                // //Second Row
                // (data[i].effectClass &&
                // data[i].classifier.includes("critdmgmulti")
                //     ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                //       "<div  class='statbox'>" +
                //       "<img style='width: 64px; height: 64px' src=" +
                //       critDmgIcon +
                //       ">" +
                //       "<p class='statboxtext'> " +
                //       parseFloat(data[i].effectClass.split(/[-_]+/)[1]) * 100 +
                //       "%</p></div>" +
                //       (data[i].effectClass.split(/[-_]+/)[2]
                //           ? "<div  class='statbox' style='margin-top: 20px; margin-bottom: 20px'>" +
                //             "<img style='width: 64px; height: 64px' src=" +
                //             critDmgIcon +
                //             ">" +
                //             "<p class='statboxtext'> " +
                //             parseFloat(data[i].effectClass.split(/[-_]+/)[2]) *
                //                 100 +
                //             "%</p></div>"
                //           : "") +
                //       (data[i].effectClass.split(/[-_]+/)[3]
                //           ? "<div  class='statbox'>" +
                //             "<img style='width: 64px; height: 64px' src=" +
                //             critDmgIcon +
                //             ">" +
                //             "<p class='statboxtext'>" +
                //             parseFloat(data[i].effectClass.split(/[-_]+/)[3]) *
                //                 100 +
                //             "%</p></div>"
                //           : "") +
                //       "</div>"
                //     : "") +
                // //Second Row
                (data[i].classifier?.[0]?.includes("critdamage")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title'>" +
                      "<img style='width: 64px; height: 64px' src=" +
                      critIcon +
                      ">" +
                      "<p class='statboxtext'> " +
                      parseFloat(data[i].classifier?.[0]?.split(/[-_]+/)[1]) *
                          100 +
                      "%</p></div></a> <div class='popover__content'>" +
                      "<h1 class='popover__message' >Increase Crit Damage</h1></div></div>" +
                      (data[i].perk.maxAllowed > 2
                          ? "<div class='popover__wrapper'><a ><div  class='statbox popover__title' style='margin-top: 20px; margin-bottom: 20px'>" +
                            "<img style='width: 64px; height: 64px' src=" +
                            critIcon +
                            ">" +
                            "<p class='statboxtext'> " +
                            parseFloat(
                                data[i].classifier?.[0]?.split(/[-_]+/)[1]
                            ) *
                                100 *
                                2 +
                            "%</p></div></a> <div class='popover__content'>" +
                            "<h1 class='popover__message' >Increase Crit Damage</h1></div></div>"
                          : "") +
                      (data[i].perk.maxAllowed == 3
                          ? "<div class='popover__wrapper' ><a ><div class='statbox popover__title' >" +
                            "<img style='width: 64px; height: 64px' src=" +
                            critIcon +
                            ">" +
                            "<p class='statboxtext'>" +
                            parseFloat(
                                data[i].classifier?.[0]?.split(/[-_]+/)[1]
                            ) *
                                100 *
                                3 +
                            "%</p></div></a> <div class='popover__content'>" +
                            "<h1 class='popover__message' >Increase Crit Damage</h1></div></div>"
                          : "") +
                      "</div>"
                    : "") +
                //First Row
                //Second Row

                ((data[i].effectClass &&
                    data[i].effectClass != "undefined" &&
                    data[i].effectClass.includes("cooldownreduce")) ||
                data[i].classifier?.[0]?.includes("cooldownreduce") ||
                data[i].anotherRankClassifier?.[0]?.includes(
                    "cooldownreduce"
                ) ||
                data[i].testRankClassifier?.[0]?.includes("cooldownreduce") ||
                data[i].extra?.includes("cooldownreduce")
                    ? testcooldown(
                          data[i].effectClass,
                          "cooldownreduce",
                          "Reduce Cooldown",
                          increaseDurationIcon,
                          data[i].anotherRankClassifier,
                          false,
                          "s"
                      )
                    : "") +
                //First Row
                //Second Row
                (data[i].extra && data[i].extra.includes("distance")
                    ? testcooldown(
                          data[i].extra,
                          "knockback",
                          "Knockback Strength",
                          imgSrc,
                          false,
                          false,
                          "%",
                          100
                      )
                    : "") +
                //First Row
                (data[i].effectClass &&
                data[i].effectClass != "undefined" &&
                data[i].classifier.includes("armorbuff")
                    ? testcooldown(
                          data[i].effectClass,
                          "armorbuff",
                          "Increase Armor",
                          armorIcon,
                          data[i].anotherRankClassifier,
                          data[i].testRankClassifier,
                          "%",
                          100,
                          null,
                          64,
                          data[i].perk.name == "Perk_2_Axe_RollGivesArmor"
                              ? "(On Roll)"
                              : ""
                      )
                    : "") +
                //Second Row
                (data[i].classifier.includes("increase_duration")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' >" +
                      "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                      increaseDurationIcon +
                      ">" +
                      "<p class='statboxtext'> " +
                      `${data[i].perk.additiveModification.increaseStatusDuration}s</p></div></a> <div class='popover__content'>` +
                      `<h1 class='popover__message' >Increase Duration of Effect</h1></div></div>` +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' margin-top: 20px; margin-bottom: 20px'>" +
                      "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                      increaseDurationIcon +
                      ">" +
                      "<p class='statboxtext'> " +
                      `${
                          data[i].perk.additiveModification
                              .increaseStatusDuration + 2
                      }s</p></div></a> <div class='popover__content'>` +
                      `<h1 class='popover__message' >Increase Duration of Effect</h1></div></div>` +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' >" +
                      "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                      increaseDurationIcon +
                      ">" +
                      "<p class='statboxtext'> " +
                      `${
                          data[i].perk.additiveModification
                              .increaseStatusDuration + 4
                      }s</p></div></a> <div class='popover__content'>` +
                      `<h1 class='popover__message' >Increase Duration of Effect</h1></div></div>` +
                      "</div>"
                    : "") +
                //Second Row
                (data[i].classifier.includes("increaseabilityrange")
                    ? "<div style='display: flex; flex-direction:column; align-items: center'>" +
                      "<div class='popover__wrapper'><a ><div class='statbox popover__title' >" +
                      "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                      imgSrc +
                      ">" +
                      "<p class='statboxtext'> " +
                      `${data[i].perk.additiveModification.increaseAbilityRange}</p></div></a> <div class='popover__content'>` +
                      `<h1 class='popover__message' >Increase Ability Range</h1></div></div>` +
                      (data[i].perk.name == "Perk_5_Axe_Holy_WhirlSize"
                          ? ""
                          : "<div class='popover__wrapper'><a ><div class='statbox popover__title' style=' margin-top: 20px; margin-bottom: 20px'>" +
                            "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                            imgSrc +
                            ">" +
                            "<p class='statboxtext'> " +
                            `${
                                data[i].perk.additiveModification
                                    .increaseAbilityRange + 1
                            }</p></div></a> <div class='popover__content'>` +
                            `<h1 class='popover__message' >Increase Ability Range</h1></div></div>` +
                            "<div class='popover__wrapper'><a ><div class='statbox popover__title' >" +
                            "<img style='width: 64px; height: 64px; margin-right: 10px' src=" +
                            imgSrc +
                            ">" +
                            "<p class='statboxtext'> " +
                            `${
                                data[i].perk.additiveModification
                                    .increaseAbilityRange + 2
                            }</p></div></a> <div class='popover__content'>` +
                            `<h1 class='popover__message' >Increase Ability Range</h1></div></div>` +
                            "</div>")
                    : "") +
                //End Rows
                "</div>" +
                "</div>";
            // console.log(div);
            //test
            selectedDiv.appendChild(div);
        }
    }

    return (
        <div
            className="Home"
            style={{
                backgroundColor: "rgb(255, 250, 250)",
                display: isLoading == true ? "none" : "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <h1
                style={{
                    paddingTop: 20,
                    marginBottom: 10,
                    fontSize: 50,
                    color: "#ff5a52",
                    textAlign: "center",
                }}
            >
                Knight's Edge Perks Balance
            </h1>
            <p style={{ fontStyle: "italic", marginBottom: 100 }}>
                Last updated: <b>Monday, Aug 1st, 2022</b>
            </p>
            <div
                id="main"
                style={{ width: "90%", display: "flex", flexDirection: "row" }}
            >
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {/* <!-- Level 1 Perks --> */}
                    <div id="rarityOne">
                        <a id="1general"></a>
                        <h1 className="titlePerkText">Common Perks</h1>
                        <h2
                            style={{ paddingBottom: 20 }}
                            id="rarityOneGeneral"
                        ></h2>
                    </div>
                    {/* <!-- Level 2 Perks --> */}
                    <div id="rarityTwo">
                        <a id="2general"></a>
                        <h1 className="titlePerkText">Uncommon Perks</h1>
                        <h2 id="rarityTwoGeneral">
                            General<div className="category"></div>
                        </h2>
                        <a id="2swords"></a>
                        <h2 id="rarityTwoSwords">
                            Swords<div className="category"></div>
                        </h2>
                        <a id="2bows"></a>
                        <h2 id="rarityTwoBows">
                            Bows<div className="category"></div>
                        </h2>
                        <a id="2axes"></a>
                        <h2 id="rarityTwoAxes">
                            Axes<div className="category"></div>
                        </h2>
                        <a id="2staves"></a>
                        <h2 id="rarityTwoStaves">
                            Staves<div className="category"></div>
                        </h2>
                    </div>

                    {/* <!-- Level 3 Perks --> */}
                    <div id="rarityThree">
                        <a id="3general"></a>
                        <h1 className="titlePerkText">Rare Perks</h1>

                        <h2 id="rarityThreeGeneral">
                            General<div className="category"></div>
                        </h2>
                        <a id="3swords"></a>
                        <h2 id="rarityThreeSwords">
                            Swords<div className="category"></div>
                        </h2>
                        <a id="3bows"></a>
                        <h2 id="rarityThreeBows">
                            Bows<div className="category"></div>
                        </h2>
                        <a id="3axes"></a>
                        <h2 id="rarityThreeAxes">
                            Axes<div className="category"></div>
                        </h2>
                        <a id="3staves"></a>
                        <h2 id="rarityThreeStaves">
                            Staves<div className="category"></div>
                        </h2>
                    </div>

                    {/* <!-- Level 4 Perks --> */}
                    <div id="rarityFour">
                        <a id="4general"></a>
                        <h1 className="titlePerkText">Epic Perks</h1>
                        <h2 id="rarityFourGeneral">
                            General<div className="category"></div>
                        </h2>
                        <a id="4swords"></a>
                        <h2 id="rarityFourSwords">
                            Swords<div className="category"></div>
                        </h2>
                        <a id="4bows"></a>
                        <h2 id="rarityFourBows">
                            Bows<div className="category"></div>
                        </h2>
                        <a id="4axes"></a>
                        <h2 id="rarityFourAxes">
                            Axes<div className="category"></div>
                        </h2>
                        <a id="4staves"></a>
                        <h2 id="rarityFourStaves">
                            Staves<div className="category"></div>
                        </h2>
                    </div>

                    {/* <!-- Level 5 Perks --> */}
                    <div id="rarityFive">
                        <a id="5general"></a>
                        <h1 className="titlePerkText">Legendary Perks</h1>
                        <h2 id="rarityFiveGeneral">
                            General<div className="category"></div>
                        </h2>
                        <a id="5swords"></a>
                        <h2 id="rarityFiveSwords">
                            Swords<div className="category"></div>
                        </h2>
                        <a id="5bows"></a>
                        <h2 id="rarityFiveBows">
                            Bows<div className="category"></div>
                        </h2>
                        <a id="5axes"></a>
                        <h2 id="rarityFiveAxes">
                            Axes<div className="category"></div>
                        </h2>
                        <a id="5staves"></a>
                        <h2 id="rarityFiveStaves">
                            Staves<div className="category"></div>
                        </h2>
                    </div>
                </div>
                <div className="filterDiv">
                    <h1 style={{ fontSize: 22, color: "#ff5a52" }}>
                        Filter Perks
                    </h1>
                    <Filter />
                </div>
            </div>
        </div>
    );
};

export default Home;
